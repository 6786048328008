import { GridList, GridListItem } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';
import cn from 'classnames';

import { TableQueryWrapper } from '@/components/TableQueryWrapper';
import Box from '@/design_system/Box';
import { InputDateRangeValue } from '@/design_system/InputDate/InputDateRange';
import Pagination, { PaginationFooter } from '@/design_system/Pagination/Pagination';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconBuilding from '@/icons/Building.svg';
import IconPerson from '@/icons/User.svg';
import { TransactionWithRelations, useTransactions } from '@/models/transaction';
import { formatDate } from '@/utils/date';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { TransactionDestination } from './components/filters/TransactionDestinationSelect';
import { TransactionOrigin } from './components/filters/TransactionOriginSelect';
import { TransactionsEmptyState } from './components/TransactionsEmptyState';
import { TransactionsFilter } from './components/TransactionsFilters';

export const TRANSACTIONS_PER_PAGE = 20;

export const Transactions = ({
  page,
  setPage,
  search,
  debouncedSearch,
  debouncedSetSearch,
  transactionOrigin,
  setTransactionOrigin,
  transactionDestination,
  setTransactionDestination,
  dateRange,
  setDateRange,
}: {
  page: number;
  setPage: (page: number) => void;
  search: string;
  debouncedSearch: string;
  debouncedSetSearch: (value: string) => void;
  transactionOrigin: TransactionOrigin;
  setTransactionOrigin: (transactionOrigin: TransactionOrigin) => void;
  transactionDestination: TransactionDestination;
  setTransactionDestination: (transactionDestination: TransactionDestination) => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const {
    data: { transactions, meta } = { transactions: [] },
    isLoading,
    isError,
  } = useTransactions({
    limit: TRANSACTIONS_PER_PAGE,
    offset: (page - 1) * TRANSACTIONS_PER_PAGE,
    search: debouncedSearch || undefined,
    sourceClient: transactionOrigin.type === 'client',
    sourceOrganizationCountry:
      transactionOrigin.type === 'organization-country' ? transactionOrigin.id : undefined,
    destinationOrganizationCountry:
      transactionDestination.type === 'organization-country'
        ? transactionDestination.id
        : undefined,
    destinationWorkshop:
      transactionDestination.type === 'workshop' ? transactionDestination.id : undefined,
    startedAtFrom: dateRange.start,
    startedAtTo: dateRange.end,
  });

  const columnWidths = {
    origin: '1fr',
    destination: '1fr',
    operationDate: '1fr',
    method: '1fr',
    amount: '1fr',
  };

  return (
    <Stack gap="1rem" style={{ height: '100%' }}>
      <TransactionsFilter
        setPage={setPage}
        search={search}
        debouncedSetSearch={debouncedSetSearch}
        transactionOrigin={transactionOrigin}
        setTransactionOrigin={setTransactionOrigin}
        transactionDestination={transactionDestination}
        setTransactionDestination={setTransactionDestination}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      <TableQueryWrapper isLoading={isLoading} isError={isError}>
        {!transactions.length ? (
          <TransactionsEmptyState />
        ) : (
          <>
            {!isMobile && (
              <Table
                ariaLabel={t({
                  id: 'accounting.transactions.table.label',
                  message: 'Transactions list',
                })}
                columnWidths={[
                  columnWidths.origin,
                  columnWidths.destination,
                  columnWidths.operationDate,
                  columnWidths.method,
                  columnWidths.amount,
                ]}
              >
                <Header>
                  <Row>
                    <Column>
                      <Trans id="accounting.transactions.table.column.origin.label">Origin</Trans>
                    </Column>

                    <Column>
                      <Trans id="accounting.transactions.table.column.destination.label">
                        Destination
                      </Trans>
                    </Column>

                    <Column>
                      <Trans id="accounting.transactions.table.column.operation-date.label">
                        Operation Date
                      </Trans>
                    </Column>

                    <Column>
                      <Trans id="accounting.transactions.table.column.method.label">Method</Trans>
                    </Column>

                    <Column justifyContent="end">
                      <Trans id="accounting.transactions.table.column.amount.label">Amount</Trans>
                    </Column>
                  </Row>
                </Header>

                <Body>
                  {transactions.map((transaction) => (
                    <TransactionRow key={transaction.id} transaction={transaction} />
                  ))}
                </Body>

                <PaginationFooter
                  page={page}
                  itemsPerPage={TRANSACTIONS_PER_PAGE}
                  count={meta?.count}
                  onPageChange={setPage}
                />
              </Table>
            )}

            {isMobile && (
              <>
                <GridList
                  aria-label={t({
                    id: 'accounting.transactions.table.label',
                    message: 'Transactions list',
                  })}
                  style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  {transactions.map((transaction) => (
                    <TransactionCard key={transaction.id} transaction={transaction} />
                  ))}
                </GridList>
                <Pagination
                  page={page}
                  itemsPerPage={TRANSACTIONS_PER_PAGE}
                  count={meta?.count}
                  onPageChange={setPage}
                />
              </>
            )}
          </>
        )}
      </TableQueryWrapper>
    </Stack>
  );
};

const TransactionRow = ({ transaction }: { transaction: TransactionWithRelations }) => {
  return (
    <Row to={`transactions/${transaction.id}`}>
      <OriginCell transaction={transaction} />
      <DestinationCell transaction={transaction} />
      <OperationDateCell transaction={transaction} />
      <MethodCell />
      <Cell justifyContent="end">
        <AmountValue transaction={transaction} />
      </Cell>
    </Row>
  );
};

const OriginCell = ({ transaction }: { transaction: TransactionWithRelations }) => {
  const origin =
    transaction.type === 'client_payment_in'
      ? transaction.sourceClient?.name
      : transaction.type === 'workshop_payment_out'
        ? transaction.onBehalfOfOrganizationCountry?.name
        : undefined;

  return (
    <Cell isLink>
      <Stack gap="0.5rem" row flexWrap="nowrap" className="paragraph-100-regular">
        {transaction.type === 'client_payment_in' && <IconPerson style={{ fontSize: '1.25rem' }} />}
        {transaction.type === 'workshop_payment_out' && (
          <IconBuilding style={{ fontSize: '1.25rem' }} />
        )}
        {origin ?? '-'}
      </Stack>
    </Cell>
  );
};

const DestinationCell = ({ transaction }: { transaction: TransactionWithRelations }) => {
  const destination =
    transaction.type === 'client_payment_in'
      ? transaction.onBehalfOfOrganizationCountry?.name
      : transaction.type === 'workshop_payment_out'
        ? transaction.destinationWorkshop?.name
        : undefined;

  return <Cell>{destination ?? '-'}</Cell>;
};

const OperationDateCell = ({ transaction }: { transaction: TransactionWithRelations }) => {
  const operationDate = transaction.startedAt
    ? formatDate(new Date(transaction.startedAt), { dateStyle: 'medium' })
    : undefined;

  return <Cell>{operationDate ?? '-'}</Cell>;
};

const MethodCell = () => {
  return (
    <Cell>
      <Trans id="accounting.transactions.table.column.method.via-prolong-stripe">
        Via Prolong (Stripe)
      </Trans>
    </Cell>
  );
};

const AmountValue = ({ transaction }: { transaction: TransactionWithRelations }) => {
  return (
    <div className={cn('paragraph-100-medium')}>
      {formatCurrency(transaction.amount, transaction.currency)}
    </div>
  );
};

const TransactionCard = ({ transaction }: { transaction: TransactionWithRelations }) => {
  const origin =
    transaction.type === 'client_payment_in'
      ? transaction.sourceClient?.name
      : transaction.type === 'workshop_payment_out'
        ? transaction.onBehalfOfOrganizationCountry?.name
        : undefined;

  const destination =
    transaction.type === 'client_payment_in'
      ? transaction.onBehalfOfOrganizationCountry?.name
      : transaction.type === 'workshop_payment_out'
        ? transaction.destinationWorkshop?.name
        : undefined;

  return (
    <GridListItem href={`accounting/transactions/${transaction.id}`} textValue={origin}>
      <Box padding="16px" role="row">
        <Stack row justifyContent="space-between" alignItems="center">
          <Stack row gap="4px">
            <div style={{ fontSize: '1.25rem', paddingTop: '2px' }}>
              {transaction.sourceClient ? <IconPerson /> : <IconBuilding />}
            </div>
            <Stack className="paragraph-200-regular">
              <span className="paragraph-100-regular">{origin}</span>
              <span className="text-secondary">
                <Trans id="accounting.transactions.table.destination.to">To: {destination}</Trans>
              </span>
              <span className="text-secondary" style={{ marginTop: '4px' }}>
                {transaction.startedAt
                  ? formatDate(new Date(transaction.startedAt), { dateStyle: 'medium' })
                  : '-'}
              </span>
            </Stack>
          </Stack>
          <AmountValue transaction={transaction} />
        </Stack>
      </Box>
    </GridListItem>
  );
};
