import { CSSProperties } from 'react';

import IconDone from '@/icons/Done.svg';
import IconError from '@/icons/Error.svg';
import IconInfo from '@/icons/Info.svg';
import IconWarning from '@/icons/Warning.svg';
import { createBEMClasses } from '@/utils/classname';

import './Message.css';

const { block, element } = createBEMClasses('message');

export interface MessageProps {
  type?: 'error' | 'success' | 'info' | 'warning';
  showMessageIcon?: boolean;
  children: React.ReactNode;
  style?: CSSProperties;
}

const Message = ({ type = 'info', showMessageIcon = true, children, style }: MessageProps) => {
  const icon =
    type === 'info' ? (
      <IconInfo />
    ) : type === 'success' ? (
      <IconDone />
    ) : type === 'warning' ? (
      <IconWarning />
    ) : (
      <IconError />
    );

  return (
    <div className={block({ type })} style={style}>
      {showMessageIcon && icon}
      <div className={element('text')}>{children}</div>
    </div>
  );
};

export default Message;
