import { useEffect, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';

import { FiltersButton, FiltersDrawer } from '@/components/ListFilters';
import { InputDateRange } from '@/design_system/InputDate';
import { InputDateRangeValue } from '@/design_system/InputDate/InputDateRange';
import Stack from '@/design_system/Stack';
import useViewPort from '@/utils/useViewport';

import { InvoiceLineOrganizationCountrySelect } from './filters/InvoiceLineOrganizationCountrySelect';
import {
  InvoiceLinePaymentStrategy,
  InvoiceLinePaymentStrategySelect,
} from './filters/InvoiceLinePaymentStrategySelect';
import { InvoiceLineSearch } from './filters/InvoiceLineSearch';
import { InvoiceLineWorkshopSelect } from './filters/InvoiceLineWorkshopSelect';

export const InvoiceLinesFilter = ({
  setPage,
  search,
  debouncedSetSearch,
  invoiceLineWorkshop,
  setInvoiceLineWorkshop,
  invoiceLineOrganizationCountry,
  setInvoiceLineOrganizationCountry,
  invoiceLinePaymentStrategy,
  setInvoiceLinePaymentStrategy,
  dateRange,
  setDateRange,
}: {
  setPage: (page: number) => void;
  search: string;
  debouncedSetSearch: (value: string) => void;
  invoiceLineWorkshop: string;
  setInvoiceLineWorkshop: (workshop: string) => void;
  invoiceLineOrganizationCountry: string;
  setInvoiceLineOrganizationCountry: (organizationCountry: string) => void;
  invoiceLinePaymentStrategy: InvoiceLinePaymentStrategy | '';
  setInvoiceLinePaymentStrategy: (
    invoiceLinePaymentStrategy: InvoiceLinePaymentStrategy | ''
  ) => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const { isMobile } = useViewPort();

  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);

  const hasActiveFilters =
    invoiceLineWorkshop !== '' ||
    invoiceLineOrganizationCountry !== '' ||
    invoiceLinePaymentStrategy !== '' ||
    dateRange.option !== 'any';

  return (
    <ErrorBoundary>
      <Stack row gap="1rem">
        <InvoiceLineSearch search={search} debouncedSetSearch={debouncedSetSearch} />

        {!isMobile && (
          <>
            <InvoiceLineWorkshopSelect
              setPage={setPage}
              invoiceLineWorkshop={invoiceLineWorkshop}
              setInvoiceLineWorkshop={setInvoiceLineWorkshop}
              style={{ flex: 1, minWidth: '200px', maxWidth: '320px' }}
            />
            <InvoiceLineOrganizationCountrySelect
              setPage={setPage}
              invoiceLineOrganizationCountry={invoiceLineOrganizationCountry}
              setInvoiceLineOrganizationCountry={setInvoiceLineOrganizationCountry}
              style={{ flex: 1, minWidth: '200px', maxWidth: '320px' }}
            />
            <InvoiceLinePaymentStrategySelect
              setPage={setPage}
              invoiceLinePaymentStrategy={invoiceLinePaymentStrategy}
              setInvoiceLinePaymentStrategy={setInvoiceLinePaymentStrategy}
              style={{ flex: 1, minWidth: '200px', maxWidth: '320px' }}
            />
            <InputDateRange
              value={dateRange}
              onChange={(newRange) => {
                setDateRange(newRange);
                setPage(1);
              }}
              style={{ flex: 1, minWidth: '200px', maxWidth: '320px' }}
            />
          </>
        )}

        {isMobile && (
          <>
            <FiltersButton
              hasActiveFilters={hasActiveFilters}
              isFiltersDrawerOpen={isFiltersDrawerOpen}
              setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
            />
            <MobileFilters
              isOpen={isFiltersDrawerOpen}
              onOpenChange={setIsFiltersDrawerOpen}
              setPage={setPage}
              invoiceLineWorkshop={invoiceLineWorkshop}
              setInvoiceLineWorkshop={setInvoiceLineWorkshop}
              invoiceLineOrganizationCountry={invoiceLineOrganizationCountry}
              setInvoiceLineOrganizationCountry={setInvoiceLineOrganizationCountry}
              invoiceLinePaymentStrategy={invoiceLinePaymentStrategy}
              setInvoiceLinePaymentStrategy={setInvoiceLinePaymentStrategy}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </>
        )}
      </Stack>
    </ErrorBoundary>
  );
};

const MobileFilters = ({
  isOpen,
  onOpenChange,
  setPage,
  invoiceLineWorkshop,
  setInvoiceLineWorkshop,
  invoiceLineOrganizationCountry,
  setInvoiceLineOrganizationCountry,
  invoiceLinePaymentStrategy,
  setInvoiceLinePaymentStrategy,
  dateRange,
  setDateRange,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  setPage: (page: number) => void;
  invoiceLineWorkshop: string;
  setInvoiceLineWorkshop: (workshop: string) => void;
  invoiceLineOrganizationCountry: string;
  setInvoiceLineOrganizationCountry: (organizationCountry: string) => void;
  invoiceLinePaymentStrategy: InvoiceLinePaymentStrategy | '';
  setInvoiceLinePaymentStrategy: (
    invoiceLinePaymentStrategy: InvoiceLinePaymentStrategy | ''
  ) => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const [newInvoiceLineWorkshop, setNewInvoiceLineWorkshop] = useState(invoiceLineWorkshop);
  const [newInvoiceLineOrganizationCountry, setNewInvoiceLineOrganizationCountry] = useState(
    invoiceLineOrganizationCountry
  );
  const [newInvoiceLinePaymentStrategy, setNewInvoiceLinePaymentStrategy] = useState(
    invoiceLinePaymentStrategy
  );
  const [newDateRange, setNewDateRange] = useState(dateRange);

  const handleClearFilters = () => {
    setNewInvoiceLineWorkshop('');
    setNewInvoiceLineOrganizationCountry('');
    setNewInvoiceLinePaymentStrategy('');
    setNewDateRange({ option: 'any' });
  };

  const handleApplyFilters = () => {
    const hasInvoiceLineWorkshopBeenUpdated = invoiceLineWorkshop !== newInvoiceLineWorkshop;
    const hasInvoiceLineOrganizationCountryBeenUpdated =
      invoiceLineOrganizationCountry !== newInvoiceLineOrganizationCountry;
    const hasInvoiceLinePaymentStrategyBeenUpdated =
      invoiceLinePaymentStrategy !== newInvoiceLinePaymentStrategy;
    const hasDateRangeBeenUpdated =
      dateRange.option !== newDateRange.option ||
      dateRange.start !== newDateRange.start ||
      dateRange.end !== newDateRange.end;

    if (hasInvoiceLineWorkshopBeenUpdated) {
      setInvoiceLineWorkshop(newInvoiceLineWorkshop);
    }

    if (hasInvoiceLineOrganizationCountryBeenUpdated) {
      setInvoiceLineOrganizationCountry(newInvoiceLineOrganizationCountry);
    }

    if (hasInvoiceLinePaymentStrategyBeenUpdated) {
      setInvoiceLinePaymentStrategy(newInvoiceLinePaymentStrategy);
    }

    if (hasDateRangeBeenUpdated) {
      setDateRange(newDateRange);
    }

    if (
      hasInvoiceLineWorkshopBeenUpdated ||
      hasInvoiceLineOrganizationCountryBeenUpdated ||
      hasInvoiceLinePaymentStrategyBeenUpdated ||
      hasDateRangeBeenUpdated
    ) {
      setPage(1);
    }

    onOpenChange(false);
  };

  // Those useEffect aim to fill the local state of the drawer with the already applied filters
  useEffect(() => {
    setNewInvoiceLineWorkshop(invoiceLineWorkshop);
  }, [invoiceLineWorkshop, isOpen]);

  useEffect(() => {
    setNewInvoiceLineOrganizationCountry(invoiceLineOrganizationCountry);
  }, [invoiceLineOrganizationCountry, isOpen]);

  useEffect(() => {
    setNewInvoiceLinePaymentStrategy(invoiceLinePaymentStrategy);
  }, [invoiceLinePaymentStrategy, isOpen]);

  useEffect(() => {
    setNewDateRange(dateRange);
  }, [dateRange, isOpen]);

  return (
    <FiltersDrawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <InvoiceLineWorkshopSelect
        invoiceLineWorkshop={newInvoiceLineWorkshop}
        setInvoiceLineWorkshop={setNewInvoiceLineWorkshop}
        style={{ flex: 1 }}
      />
      <InvoiceLineOrganizationCountrySelect
        invoiceLineOrganizationCountry={newInvoiceLineOrganizationCountry}
        setInvoiceLineOrganizationCountry={setNewInvoiceLineOrganizationCountry}
        style={{ flex: 1 }}
      />
      <InvoiceLinePaymentStrategySelect
        invoiceLinePaymentStrategy={newInvoiceLinePaymentStrategy}
        setInvoiceLinePaymentStrategy={setNewInvoiceLinePaymentStrategy}
        style={{ flex: 1 }}
      />
      <InputDateRange value={newDateRange} onChange={setNewDateRange} style={{ flex: 1 }} />
    </FiltersDrawer>
  );
};
