import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';
import { useQueryClient } from '@tanstack/react-query';

import Loader from '@/components/Loader';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Drawer, { DrawerBody, DrawerFooter, DrawerHeader } from '@/design_system/Drawer';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { useShowToast } from '@/design_system/Toast';
import IconCancel from '@/icons/Cancel.svg';
import {
  InvoiceWithMoreRelations,
  useDeleteInvoice,
  useInvoice,
  useReportIssue,
  useSubmitInvoice,
  useValidateInvoice,
} from '@/models/invoice';
import { useCurrentSession } from '@/services/auth';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { InvoiceAmount } from './components/InvoiceAmount';
import { InvoiceDestination } from './components/InvoiceDestination';
import { InvoiceIssue } from './components/InvoiceIssue';
import { InvoiceIssuingDate } from './components/InvoiceIssuingDate';
import { InvoiceLines } from './components/InvoiceLines';
import { InvoiceMedia } from './components/InvoiceMedia';
import { InvoiceNumber } from './components/InvoiceNumber';
import { InvoiceOrigin } from './components/InvoiceOrigin';
import { InvoicePaymentDueDate } from './components/InvoicePaymentDueDate';
import { InvoicePaymentReceipt } from './components/InvoicePaymentReceipt';
import { InvoicePaymentStrategy } from './components/InvoicePaymentStrategy';

export const Invoice = () => {
  const { t } = useLingui();
  const { id } = useParams();
  const showToast = useShowToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentSession } = useCurrentSession();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(true);

  const { data: invoice, error } = useInvoice(id);
  const { mutate: deleteInvoice } = useDeleteInvoice(id!);

  const isCreatingInvoice =
    !!invoice && currentSession?.id === invoice.creatorId && invoice.status === 'draft';

  const close = () => {
    setIsOpen(false);
    queryClient.invalidateQueries({ queryKey: ['invoices'] });

    // Wait for the Drawer CSS exit animation to finish before navigating away
    setTimeout(() => {
      navigate((location.state?.onCancel as string) ?? '/accounting');
    }, 150);
  };

  const deleteAndClose = () => {
    setIsOpen(false);

    // Wait for the Drawer CSS exit animation to finish before navigating away
    setTimeout(() => {
      deleteInvoice();

      navigate((location.state?.onCancel as string) ?? '/accounting', { replace: true });
    }, 150);
  };

  useEffect(() => {
    if (error) {
      showToast({
        type: 'error',
        text: t({
          id: 'invoice.fetch.error',
          message: 'Unable to retrieve the invoice. Please try again later.',
        }),
      });

      close();
    }
    // Prevents the toast from being displayed twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <Drawer
      isOpen={isOpen}
      position="bottom"
      onOpenChange={isCreatingInvoice ? deleteAndClose : close}
      closeConfirmation={
        isCreatingInvoice
          ? {
              title: <Trans id="invoice.close.title">Discard invoice</Trans>,
              content: (
                <Trans id="invoice.close.content">
                  You&apos;re about to leave this page without saving your changes. The invoice
                  draft will be discarded.
                  <br />
                  Are you sure?
                </Trans>
              ),
              confirm: <Trans id="invoice.close.confirm">Discard</Trans>,
            }
          : undefined
      }
    >
      {!invoice ? <InvoiceLoading /> : <InvoiceContent invoice={invoice} onClose={close} />}
    </Drawer>
  );
};

const InvoiceLoading = () => {
  return (
    <>
      <DrawerHeader>
        <h1 className="headline-200-bold text-primary">
          <Trans id="invoice.loading.title">Invoice</Trans>
        </h1>
      </DrawerHeader>
      <DrawerBody>
        <Stack alignItems="center" justifyContent="center" style={{ height: '100%' }}>
          <Loader />
        </Stack>
      </DrawerBody>
      <DrawerFooter />
    </>
  );
};

interface InvoiceContextData {
  invoice: InvoiceWithMoreRelations;
  showErrors: boolean;
  errors: {
    number: boolean;
    origin: boolean;
    destination: boolean;
    issuingDate: boolean;
    paymentDueDate: boolean;
    amount: boolean;
    lines: boolean;
    media: boolean;
  };
  isDisabled: boolean;
  isCreatingInvoice: boolean;
  isValidatingInvoice: boolean;
}

const InvoiceContext = createContext({} as InvoiceContextData);

export const useInvoiceContext = () => useContext(InvoiceContext);

const InvoiceContent = ({
  invoice,
  onClose,
}: {
  invoice: InvoiceWithMoreRelations;
  onClose: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const [showErrors, setShowErrors] = useState(false);
  const isCreatingInvoice = currentSession?.id === invoice.creatorId && invoice.status === 'draft';
  const isValidatingInvoice =
    !!invoice.destinationOrganizationCountry &&
    currentSession!.hasPermission('validate_invoice', {
      organizationId: invoice.destinationOrganizationCountry?.organizationId,
    }) &&
    invoice.status === 'pending-validation';
  const isDisabled = !isCreatingInvoice && !isValidatingInvoice;

  const errors = {
    number: !invoice.number,
    origin: !invoice.originWorkshop,
    destination: !invoice.destinationOrganizationCountry,
    issuingDate: !invoice.issuingDate,
    paymentDueDate: !invoice.paymentDueDate,
    amount: !invoice.amount,
    lines: !invoice.lines.length,
    media: !invoice.media.filter((medium) => medium.type === 'workshop-invoice').length,
  };

  return (
    <InvoiceContext
      value={{
        invoice,
        showErrors,
        errors,
        isDisabled,
        isCreatingInvoice,
        isValidatingInvoice,
      }}
    >
      <DrawerHeader>
        <h1 className="headline-200-bold text-primary">
          {invoice.status === 'draft' ? (
            <Trans id="invoice.title.new">New invoice</Trans>
          ) : (
            <Trans id="invoice.title">Invoice {invoice.number}</Trans>
          )}
        </h1>
      </DrawerHeader>
      <DrawerBody className="text-primary">
        <Stack gap="16px" padding={isMobile ? '16px' : '24px 32px'} style={{ minHeight: '100%' }}>
          <InvoiceIssue />
          <Stack row gap="32px" style={{ flex: '1' }}>
            <InvoiceMedia />
            <Stack gap="1rem" style={{ flex: '1 1 650px', maxWidth: '100%' }}>
              <InvoiceNumber />
              <Stack row gap="1rem">
                <InvoiceOrigin />
                <InvoiceIssuingDate />
              </Stack>
              <Stack row gap="1rem">
                <InvoiceDestination />
                <InvoicePaymentDueDate />
              </Stack>
              <InvoicePaymentStrategy />
              <InvoiceAmount />
              <InvoiceLines />
              <InvoicePaymentReceipt />
            </Stack>
          </Stack>
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <InvoiceFooter onClose={onClose} setShowErrors={setShowErrors} />
      </DrawerFooter>
    </InvoiceContext>
  );
};

const InvoiceFooter = ({
  onClose,
  setShowErrors,
}: {
  onClose: () => void;
  setShowErrors: (showErrors: boolean) => void;
}) => {
  const { invoice, showErrors, errors, isDisabled, isCreatingInvoice, isValidatingInvoice } =
    useInvoiceContext();

  const [apiError, setApiError] = useState<string | null>(null);

  const { mutateAsync: submit, isPending: isPendingSubmit } = useSubmitInvoice(invoice.id);
  const { mutateAsync: validate, isPending: isPendingValidate } = useValidateInvoice(invoice.id);

  const hasErrors = Object.values(errors).some((value) => value);

  const onAction = (action: () => Promise<void>) => async () => {
    setShowErrors(true);

    if (hasErrors) {
      return;
    }

    setApiError(null);

    try {
      await action();
      onClose();
    } catch (err: any) {
      setApiError((err.message as string) ?? '');
    }
  };

  return (
    <Stack row gap="24px" alignItems="center" justifyContent="flex-end">
      {showErrors && hasErrors && (
        <Message type="error">
          <Trans id="invoice.form.global-error">Please fill all the required fields</Trans>
        </Message>
      )}
      {apiError !== null && (
        <span className="paragraph-100-regular text-danger">
          {apiError ?? (
            <Trans id="invoice.form.api-error.unknown">
              An unknown error occurred. Please try again later.
            </Trans>
          )}
        </span>
      )}
      <Stack alignItems="flex-end">
        <span className="paragraph-200-regular text-secondary">
          <Trans id="invoice.form.lines-total-amount">Services total amount</Trans>
        </span>
        <span className="paragraph-50-medium">
          {invoice.lines.length
            ? formatCurrency(
                invoice.lines.reduce((acc, line) => acc + line.price.amount, 0),
                invoice.currency
              )
            : '-'}
        </span>
      </Stack>
      <div style={{ height: '24px', borderLeft: '1px solid var(--color-neutral-300)' }} />
      {isDisabled && (
        <Button variant="secondary" onPress={onClose}>
          <Trans id="invoice.form.close">Close</Trans>
        </Button>
      )}
      {isCreatingInvoice && (
        <Button variant="primary" onPress={onAction(submit)} isLoading={isPendingSubmit}>
          <Trans id="invoice.form.submit">Submit</Trans>
        </Button>
      )}
      {isValidatingInvoice && (
        <Stack row gap="16px" alignItems="center">
          <ReportIssue isDisabled={isPendingValidate} />
          <Button variant="primary" onPress={onAction(validate)} isLoading={isPendingValidate}>
            <Trans id="invoice.form.validate">Validate</Trans>
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

const ReportIssue = ({ isDisabled }: { isDisabled: boolean }) => {
  const { t } = useLingui();
  const { invoice } = useInvoiceContext();

  const [isOpen, setIsOpen] = useState(false);
  const [issue, setIssue] = useState('');
  const [error, setError] = useState<string | null>(null);

  const { mutateAsync: reportIssue, isPending: isPendingReportIssue } = useReportIssue(invoice.id);

  const onReportIssue = async () => {
    setError(null);

    try {
      await reportIssue({ issue });
      setIsOpen(false);
    } catch (err: any) {
      setError((err.message as string) ?? '');
    }
  };

  return (
    <>
      <Button variant="secondary" disabled={isDisabled} onPress={() => setIsOpen(true)}>
        <IconCancel />
        <Trans id="invoice.form.report-issue">Report an issue</Trans>
      </Button>
      <Dialog
        title={<Trans id="invoice.form.report-issue.title">Report an issue</Trans>}
        isOpen={isOpen}
        onOpenChange={(isOpen) => {
          if (!isPendingReportIssue) {
            setIsOpen(isOpen);
          }
        }}
        style={{ maxWidth: '550px' }}
      >
        <main>
          <Stack gap="8px">
            <p className="paragraph-100-regular">
              <Trans id="invoice.form.report-issue.text">
                If the invoice seems invalid, you can report it. This will archive it and notify the
                store and accounting team to resolve the issue.
              </Trans>
            </p>
            <TextArea
              rows={3}
              value={issue}
              onChange={(event) => setIssue(event.target.value)}
              placeholder={t({
                id: 'invoice.form.report-issue.placeholder',
                message: 'Explain the issue...',
              })}
            />
          </Stack>
        </main>
        <footer>
          <Stack row gap="16px" justifyContent="flex-end">
            {error !== null && (
              <span className="paragraph-100-regular text-danger">
                {error ?? (
                  <Trans id="invoice.form.report-issue.error">
                    An unknown error occurred. Please try again later.
                  </Trans>
                )}
              </span>
            )}
            <Button variant="danger" onPress={onReportIssue} isLoading={isPendingReportIssue}>
              <Trans id="invoice.form.report-issue.report">Report an issue</Trans>
            </Button>
          </Stack>
        </footer>
      </Dialog>
    </>
  );
};
