import { CSSProperties } from 'react';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { InvoiceStatus } from '@/models/invoice';

export const InvoiceStatusSelect = ({
  setPage,
  invoiceStatus,
  setInvoiceStatus,
  style,
}: {
  setPage?: (page: number) => void;
  invoiceStatus: InvoiceStatus | '';
  setInvoiceStatus: (invoiceStatus: InvoiceStatus | '') => void;
  style?: CSSProperties;
}) => {
  const { i18n, t } = useLingui();

  return (
    <InputSelect
      variant="select"
      placeholder={t({
        id: 'accounting.invoices.filters.status.placeholder',
        message: 'Status: All',
      })}
      aria-label={t({
        id: 'accounting.invoices.filters.status.label',
        message: 'Status',
      })}
      value={options.find((option) => invoiceStatus === option.id) ?? null}
      isClearable
      options={options}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => i18n._(option.text)}
      onChange={(option, action) => {
        if (action.action === 'clear') {
          setInvoiceStatus('');
        } else if (option) {
          setInvoiceStatus(option.id);
        }

        setPage?.(1);
      }}
      style={style}
    />
  );
};

const options = [
  {
    id: 'pending-validation',
    text: msg({
      id: 'accounting.invoices.filters.status.options.pending-validation',
      message: 'Pending validation',
    }),
  },
  {
    id: 'handled-by-accounting',
    text: msg({
      id: 'accounting.invoices.filters.status.options.handled-by-accounting',
      message: 'Handled by accounting',
    }),
  },
  {
    id: 'paid',
    text: msg({
      id: 'accounting.invoices.filters.status.options.paid',
      message: 'Paid',
    }),
  },
  {
    id: 'issue',
    text: msg({
      id: 'accounting.invoices.filters.status.options.issue',
      message: 'Issue reported',
    }),
  },
] as const;
