import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react';
import { Plural, Trans } from '@lingui/react/macro';

import IconCalendarOverdue from '@/icons/CalendarOverdue.svg';
import IconCalendarReminder from '@/icons/CalendarReminder.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { useGetArticleName } from '@/models/article';
import type { TNotificationOfType } from '@/models/notification';

// Previously, this notification was associated to either a request or a shipment
// Now, it's always associated to a request and an article
export const DueDateAlertingNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'due_date_alerting'>;
}) => {
  const { _ } = useLingui();
  const getArticleName = useGetArticleName();

  const taskWording = _(TASK_WORDING[notification.data.task]);
  const reference = notification.request?.reference ?? notification.shipment?.reference;
  const articleName = notification.article
    ? getArticleName({ article: notification.article })
    : undefined;

  const label =
    notification.data.dueDelay === 0
      ? articleName
        ? _(
            msg({
              id: 'notification.due-date.due-today.label.article',
              message: `You have ${taskWording} due today on the item ${articleName} from ${reference}`,
            })
          )
        : _(
            msg({
              id: 'notification.due-date.due-today.label',
              message: `You have ${taskWording} due today on ${reference}`,
            })
          )
      : articleName
        ? _(
            msg({
              id: 'notification.due-date.overdue.label.article',
              message: `You have ${taskWording} overdue on the item ${articleName} from ${reference}`,
            })
          )
        : _(
            msg({
              id: 'notification.due-date.overdue.label',
              message: `You have ${taskWording} overdue on ${reference}`,
            })
          );

  return (
    <BaseNotification
      icon={notification.data.dueDelay === 0 ? <IconCalendarReminder /> : <IconCalendarOverdue />}
      type={notification.data.dueDelay === 0 ? 'warning' : 'danger'}
      notification={notification}
      notificationLabel={label}
      notificationHeader={
        notification.data.dueDelay === 0 ? (
          articleName ? (
            <Trans id="notification.due-date.due-today.article">
              You have {taskWording} <span className="paragraph-100-medium">due today</span> on the
              item {articleName}.
            </Trans>
          ) : (
            <Trans id="notification.due-date.due-today">
              You have {taskWording} <span className="paragraph-100-medium">due today</span>.
            </Trans>
          )
        ) : articleName ? (
          <Trans id="notification.due-date.overdue.article">
            You have {taskWording}{' '}
            <span className="paragraph-100-medium">
              overdue by <Plural value={notification.data.dueDelay} one="# day" other="# days" />
            </span>{' '}
            on the item {articleName}.
          </Trans>
        ) : (
          <Trans id="notification.due-date.overdue">
            You have {taskWording}{' '}
            <span className="paragraph-100-medium">
              overdue by <Plural value={notification.data.dueDelay} one="# day" other="# days" />.
            </span>
          </Trans>
        )
      }
    />
  );
};

const TASK_WORDING = {
  choose_article_service: msg({
    id: 'notification.due-date.choose_article_service',
    message: 'a service choice task',
  }),
  accept_dispatch_proposal: msg({
    id: 'notification.due-date.accept_dispatch_proposal',
    message: 'a quote review task',
  }),
  validate_external_payment: msg({
    id: 'notification.due-date.validate_external_payment',
    message: 'a payment task',
  }),
  accept_dispatch: msg({
    id: 'notification.due-date.accept_dispatch',
    message: 'a job validation task',
  }),
  analyze_article: msg({
    id: 'notification.due-date.analyze_article',
    message: 'an analysis task',
  }),
  accept_requalification: msg({
    id: 'notification.due-date.accept_requalification',
    message: 'a re-qualification analysis task',
  }),
  repair_article: msg({
    id: 'notification.due-date.repair_article',
    message: 'a repair task',
  }),
  create_shipment: msg({
    id: 'notification.due-date.create_shipment',
    message: 'a shipment creation task',
  }),
  prepare_shipment: msg({
    id: 'notification.due-date.prepare_shipment',
    message: 'a shipment preparation task',
  }),
  finalise_shipment: msg({
    id: 'notification.due-date.finalise_shipment',
    message: 'a shipment finalisation task',
  }),
  dropoff_shipment: msg({
    id: 'notification.due-date.dropoff_shipment',
    message: 'a shipment drop-off task',
  }),
  verify_shipment_reception: msg({
    id: 'notification.due-date.verify_shipment_reception',
    message: 'a shipment verification task',
  }),
} satisfies Record<TNotificationOfType<'due_date_alerting'>['data']['task'], MessageDescriptor>;
