import { useState } from 'react';
import { Trans } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { useCompleteClientPickup } from '@/models/article';
import { useInvoices } from '@/models/invoice';
import { ArticleWithRelations } from '@/models/request';
import { useFlags } from '@/services/auth';

import { WorkshopUploadInvoice } from '../ArticleInfo/ArticleWorkshopPayment';

export const CompleteClientPickup = ({
  article,
  onActionDone,
}: {
  article: ArticleWithRelations;
  onActionDone: () => void;
}) => {
  const { flags } = useFlags();

  const {
    mutateAsync: completeClientPickup,
    isPending: isPendingCompleteClientPickup,
    isSuccess: isSuccessCompleteClientPickup,
  } = useCompleteClientPickup({
    articleId: article.id,
  });

  const handleCompleteClientPickup = async () => {
    await completeClientPickup();
    setIsUploadInvoiceReminderModalOpen(false);
    onActionDone();
  };

  const { data: { invoices } = { invoices: [] }, isLoading: isLoadingInvoices } = useInvoices(
    {
      articleId: article.id,
      originWorkshop: article.workshop?.id,
    },
    {
      enabled: !!article.workshop,
    }
  );

  const shouldShowUploadInvoiceReminder =
    !!article.repairedAt &&
    !!article.workshop?.nonDigitalized &&
    !isLoadingInvoices &&
    !invoices.length &&
    flags['enable-accounting-section'];

  const [isUploadInvoiceReminderModalOpen, setIsUploadInvoiceReminderModalOpen] = useState(false);

  return (
    <>
      <Button
        variant="primary"
        size="medium"
        onPress={
          shouldShowUploadInvoiceReminder
            ? () => setIsUploadInvoiceReminderModalOpen(true)
            : handleCompleteClientPickup
        }
        isLoading={isPendingCompleteClientPickup || isSuccessCompleteClientPickup}
      >
        <Trans id="article.actions.complete_client_pickup.label">Client pickup done</Trans>
      </Button>
      <UploadInvoiceReminderModal
        isOpen={isUploadInvoiceReminderModalOpen}
        onClose={() => setIsUploadInvoiceReminderModalOpen(false)}
        onCompletePickup={handleCompleteClientPickup}
        isLoading={isPendingCompleteClientPickup}
      />
    </>
  );
};

const UploadInvoiceReminderModal = ({
  isOpen,
  onClose,
  onCompletePickup,
  isLoading,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCompletePickup: () => void;
  isLoading: boolean;
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onClose}
      title={
        <Trans id="article.actions.complete_client_pickup.upload_invoice_reminder.title">
          Invoice missing
        </Trans>
      }
    >
      <main>
        <p className="paragraph-100-regular">
          <Trans id="article.actions.complete_client_pickup.upload_invoice_reminder.text">
            You haven&apos;t uploaded the workshop&apos;s invoice yet. You can do it now before
            completing this step, or if you prefer, you can do it later from the item page or the
            accounting tab.
          </Trans>
        </p>
      </main>
      <footer>
        <Button variant="secondary" size="small" onPress={onCompletePickup} isLoading={isLoading}>
          <Trans id="article.actions.complete_client_pickup.upload_invoice_reminder.later">
            I&apos;ll do it later
          </Trans>
        </Button>
        <WorkshopUploadInvoice variant="primary" />
      </footer>
    </Dialog>
  );
};
