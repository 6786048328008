import { ArticleNoneServiceChoiceNotification } from '@/layouts/App/Navbar/NotificationList/components/Notification/ArticleNoneServiceChoiceNotification';
import { PendingInvoiceValidationNotification } from '@/layouts/App/Navbar/NotificationList/components/Notification/PendingInvoiceValidationNotification';
import { ShipmentArrivedAtPickupPointNotification } from '@/layouts/App/Navbar/NotificationList/components/Notification/ShipmentArrivedAtPickupPointNotification';
import { type TNotification } from '@/models/notification';

import { AnalysisRefusedNotification } from './AnalysisRefusedNotification';
import { ArticlesFeedbackSubmittedNotification } from './ArticlesFeedbackSubmittedNotification';
import { CommentAddedNotification } from './CommentAddedNotification';
import { DispatchProposalSubmittedNotification } from './DispatchProposalSubmittedNotification';
import { DispatchRefusedNotification } from './DispatchRefusedNotification';
import { DueDateAlertingNotification } from './DueDateAlertingNotification';
import { FinalQuoteRefusedNotification } from './FinalQuoteRefusedNotification';
import { InitialQuoteRefusedNotification } from './InitialQuoteRefusedNotification';
import { InvoicesToValidateNotification } from './InvoicesToValidateNotification';
import { MissingArticleCustomsNotification } from './MissingArticleCustomsNotification';
import { NewClientFeedbackNotification } from './NewClientFeedbackNotification';
import { NewRequalificationNotification } from './NewRequalificationNotification';
import { NewSupervisorAssignmentNotification } from './NewSupervisorAssignmentNotification';
import { PendingAnalysisNotification } from './PendingAnalysisNotification';
import { PendingClientPickupNotification } from './PendingClientPickupNotification';
import { PendingDispatchAcceptationNotification } from './PendingDispatchAcceptationNotification';
import { PendingRepairNotification } from './PendingRepairNotification';
import { PendingServiceChoiceNotification } from './PendingServiceChoiceNotification';
import { RequalificationRefusedNotification } from './RequalificationRefusedNotification';
import { ServicesToAssociateNotification } from './ServicesToAssociateNotification';
import { ShipmentIssueArchivedNotification } from './ShipmentIssueArchivedNotification';
import { ShipmentIssueReportedNotification } from './ShipmentIssueReportedNotification';
import { ShipmentIssueResolvedNotification } from './ShipmentIssueResolvedNotification';
import { ShipmentToCreateNotification } from './ShipmentToCreateNotification';

export const Notification = ({ notification }: { notification: TNotification }) => {
  switch (notification.type) {
    case 'analysis_refused':
      return <AnalysisRefusedNotification notification={notification} />;
    case 'comment_added':
      return <CommentAddedNotification notification={notification} />;
    case 'final_quote_refused':
      return <FinalQuoteRefusedNotification notification={notification} />;
    case 'initial_quote_refused':
      return <InitialQuoteRefusedNotification notification={notification} />;
    case 'pending_service_choice':
      return <PendingServiceChoiceNotification notification={notification} />;
    case 'missing_article_customs':
      return <MissingArticleCustomsNotification notification={notification} />;
    case 'new_supervisor_assignment':
      return <NewSupervisorAssignmentNotification notification={notification} />;
    case 'new_client_feedback':
      return <NewClientFeedbackNotification notification={notification} />;
    case 'pending_dispatch_acceptation':
      return <PendingDispatchAcceptationNotification notification={notification} />;
    case 'dispatch_proposal_submitted':
      return <DispatchProposalSubmittedNotification notification={notification} />;
    case 'dispatch_refused':
      return <DispatchRefusedNotification notification={notification} />;
    case 'pending_repair':
      return <PendingRepairNotification notification={notification} />;
    case 'pending_analysis':
      return <PendingAnalysisNotification notification={notification} />;
    case 'new_requalification':
      return <NewRequalificationNotification notification={notification} />;
    case 'requalification_refused':
      return <RequalificationRefusedNotification notification={notification} />;
    case 'shipment_issue_archived':
      return <ShipmentIssueArchivedNotification notification={notification} />;
    case 'shipment_issue_reported':
      return <ShipmentIssueReportedNotification notification={notification} />;
    case 'shipment_issue_resolved':
      return <ShipmentIssueResolvedNotification notification={notification} />;
    case 'shipment_to_create':
      return <ShipmentToCreateNotification notification={notification} />;
    case 'shipment_arrived_at_pickup_point':
      return <ShipmentArrivedAtPickupPointNotification notification={notification} />;
    case 'due_date_alerting':
      return <DueDateAlertingNotification notification={notification} />;
    case 'pending_client_pickup':
      return <PendingClientPickupNotification notification={notification} />;
    case 'articles_feedback_submitted':
      return <ArticlesFeedbackSubmittedNotification notification={notification} />;
    case 'article_none_service_choice':
      return <ArticleNoneServiceChoiceNotification notification={notification} />;
    case 'invoices_to_validate':
      return <InvoicesToValidateNotification notification={notification} />;
    case 'services_to_associate':
      return <ServicesToAssociateNotification notification={notification} />;
    case 'pending_invoice_validation':
      return <PendingInvoiceValidationNotification notification={notification} />;
    default:
      return;
  }
};
