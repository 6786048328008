import IconWrapper, { IconProps } from './IconWrapper';

const IconZoomIn = IconWrapper({
  name: 'zoom',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 3.5C6.74594 3.5 3.5 6.74594 3.5 10.75C3.5 14.7541 6.74594 18 10.75 18C14.7541 18 18 14.7541 18 10.75C18 6.74594 14.7541 3.5 10.75 3.5ZM2 10.75C2 5.91751 5.91751 2 10.75 2C15.5825 2 19.5 5.91751 19.5 10.75C19.5 12.8962 18.7273 14.862 17.4449 16.3842L21.7803 20.7197C22.0732 21.0126 22.0732 21.4874 21.7803 21.7803C21.4874 22.0732 21.0126 22.0732 20.7197 21.7803L16.3842 17.4449C14.862 18.7273 12.8962 19.5 10.75 19.5C5.91751 19.5 2 15.5825 2 10.75ZM11.5 7.25C11.5 6.83579 11.1642 6.5 10.75 6.5C10.3358 6.5 10 6.83579 10 7.25V10H7.25C6.83579 10 6.5 10.3358 6.5 10.75C6.5 11.1642 6.83579 11.5 7.25 11.5H10V14.25C10 14.6642 10.3358 15 10.75 15C11.1642 15 11.5 14.6642 11.5 14.25V11.5H14.25C14.6642 11.5 15 11.1642 15 10.75C15 10.3358 14.6642 10 14.25 10H11.5V7.25Z"
      />
    </svg>
  ),
});

const IconZoomOut = IconWrapper({
  name: 'zoom',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 3.5C6.74594 3.5 3.5 6.74594 3.5 10.75C3.5 14.7541 6.74594 18 10.75 18C14.7541 18 18 14.7541 18 10.75C18 6.74594 14.7541 3.5 10.75 3.5ZM2 10.75C2 5.91751 5.91751 2 10.75 2C15.5825 2 19.5 5.91751 19.5 10.75C19.5 12.8962 18.7273 14.862 17.4449 16.3842L21.7803 20.7197C22.0732 21.0126 22.0732 21.4874 21.7803 21.7803C21.4874 22.0732 21.0126 22.0732 20.7197 21.7803L16.3842 17.4449C14.862 18.7273 12.8962 19.5 10.75 19.5C5.91751 19.5 2 15.5825 2 10.75Z"
      />
      <path d="M6.5 10.75C6.5 10.3358 6.83579 10 7.25 10H14.25C14.6642 10 15 10.3358 15 10.75C15 11.1642 14.6642 11.5 14.25 11.5H7.25C6.83579 11.5 6.5 11.1642 6.5 10.75Z" />
    </svg>
  ),
});

const IconZoom = ({
  variant,
  ...props
}: {
  variant: 'in' | 'out';
} & IconProps) => {
  if (variant === 'in') return <IconZoomIn {...props} />;
  return <IconZoomOut {...props} />;
};

export default IconZoom;
