import { CSSProperties } from 'react';
import { useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useInvoicesOrigins } from '@/models/invoice';

export const InvoiceOriginSelect = ({
  invoiceOrigin,
  setInvoiceOrigin,
  style,
}: {
  invoiceOrigin: InvoiceOrigin;
  setInvoiceOrigin: (origin: InvoiceOrigin) => void;
  style?: CSSProperties;
}) => {
  const { t } = useLingui();

  const { data: invoicesOrigins, isFetching } = useInvoicesOrigins();

  const workshops = (invoicesOrigins?.workshops ?? []).map((workshop) => ({
    type: 'workshop' as const,
    label: workshop.name,
    id: workshop.id,
  }));

  const organizationCountries = (invoicesOrigins?.organizationCountries ?? []).map(
    (organizationCountry) => ({
      type: 'organization-country' as const,
      label: organizationCountry.name ?? '',
      id: organizationCountry.id,
    })
  );

  const options = [...workshops, ...organizationCountries].sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const selectedOption =
    options.find(
      (option) => option.type === invoiceOrigin?.type && option.id === invoiceOrigin?.id
    ) ?? null;

  if (!options.length) {
    return null;
  }

  return (
    <InputSelect
      variant="select"
      placeholder={t({
        id: 'accounting.invoices.origin.placeholder.all',
        message: 'Issuer: All',
      })}
      aria-label={t({ id: 'accounting.invoices.origin.label', message: 'Issuer' })}
      value={selectedOption}
      isClearable
      options={options}
      getOptionValue={(option) => `${option.type}-${option.id}`}
      getOptionLabel={(option) => option.label}
      onChange={(option, action) => {
        if (action.action === 'clear') {
          setInvoiceOrigin({ type: 'all' });
        } else if (option) {
          setInvoiceOrigin({ type: option.type, id: option.id });
        }
      }}
      isLoading={isFetching}
      style={style}
    />
  );
};

export type InvoiceOrigin = {
  type: 'all' | 'workshop' | 'organization-country';
  id?: string;
};
