import { Trans } from '@lingui/react/macro';

import InputDate from '@/design_system/InputDate';
import { useUpdateInvoice } from '@/models/invoice';

import { useInvoiceContext } from '../Invoice';

export const InvoiceIssuingDate = () => {
  const { invoice, showErrors, errors, isDisabled } = useInvoiceContext();
  const { mutate: updateInvoice } = useUpdateInvoice(invoice.id);

  return (
    <InputDate
      label={<Trans id="invoice.form.issued-at">Issuing date</Trans>}
      value={invoice.issuingDate}
      onChange={(date) =>
        updateInvoice({
          issuingDate: date,
        })
      }
      style={{ flex: '1 1 200px' }}
      isDisabled={isDisabled}
      isInvalid={showErrors && errors.issuingDate}
    />
  );
};
