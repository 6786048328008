import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { Endpoints } from '@/api';
import { useFetch } from '@/utils/fetch';

import {
  ActionType,
  ActionTypeOrganization,
  ArticleAction,
  PackActionTypeOrganization,
  PackActionTypeOrganizationAction,
} from './actionType';
import { Article } from './article';
import { Fee, FeeType } from './fee';
import { InvoicePaymentStrategy } from './invoice';
import { Model } from './model';
import { Organization } from './organization';
import { OrganizationCountry } from './organizationCountry';
import { Price } from './price';
import { Product } from './product';
import { Translation, useDatabaseTranslation } from './translation';
import { Workshop } from './workshop';

export class InvoiceLine extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  invoiceId!: string | null;
  priceId!: string;
  paymentStrategy!: InvoicePaymentStrategy | null;
  createdAt!: string;
}

export const instanciateInvoiceLineWithRelations = (
  invoiceLine: Endpoints['GET /invoice-lines']['response']['invoiceLines'][number]
) =>
  new InvoiceLine(invoiceLine)
    .with(
      'price',
      new Price(invoiceLine.price)
        .with(
          'articleAction',
          invoiceLine.price.articleAction
            ? new ArticleAction(invoiceLine.price.articleAction)
                .with(
                  'actionTypeOrganization',
                  invoiceLine.price.articleAction.actionTypeOrganization
                    ? new ActionTypeOrganization(
                        invoiceLine.price.articleAction.actionTypeOrganization
                      ).with(
                        'actionType',
                        new ActionType(
                          invoiceLine.price.articleAction.actionTypeOrganization.actionType
                        ).with(
                          'name',
                          new Translation(
                            invoiceLine.price.articleAction.actionTypeOrganization.actionType.name
                          )
                        )
                      )
                    : null
                )
                .with(
                  'packActionTypeOrganization',
                  invoiceLine.price.articleAction.packActionTypeOrganization
                    ? new PackActionTypeOrganization(
                        invoiceLine.price.articleAction.packActionTypeOrganization
                      )
                        .with(
                          'name',
                          new Translation(
                            invoiceLine.price.articleAction.packActionTypeOrganization.name
                          )
                        )
                        .with(
                          'actions',
                          invoiceLine.price.articleAction.packActionTypeOrganization.actions.map(
                            (subAction) =>
                              new PackActionTypeOrganizationAction(subAction).with(
                                'actionType',
                                new ActionType(subAction.actionType).with(
                                  'name',
                                  new Translation(subAction.actionType.name)
                                )
                              )
                          )
                        )
                    : null
                )
            : null
        )
        .with(
          'fee',
          invoiceLine.price.fee
            ? new Fee(invoiceLine.price.fee).with(
                'feeType',
                new FeeType(invoiceLine.price.fee.feeType).with(
                  'name',
                  new Translation(invoiceLine.price.fee.feeType.name)
                )
              )
            : null
        )
        .with(
          'originOrganizationCountry',
          invoiceLine.price.originOrganizationCountry
            ? new OrganizationCountry(invoiceLine.price.originOrganizationCountry).with(
                'organization',
                new Organization(invoiceLine.price.originOrganizationCountry.organization)
              )
            : null
        )
        .with(
          'destinationWorkshop',
          invoiceLine.price.destinationWorkshop
            ? new Workshop(invoiceLine.price.destinationWorkshop)
            : null
        )
    )
    .with(
      'article',
      new Article(invoiceLine.article)
        .with(
          'product',
          invoiceLine.article.product ? new Product(invoiceLine.article.product) : null
        )
        .with('request', invoiceLine.article.request)
        .with('archivedRequest', invoiceLine.article.archivedRequest)
    );

export type InvoiceLineWithRelations = ReturnType<typeof instanciateInvoiceLineWithRelations>;

export const useInvoiceLines = (
  params?: Endpoints['GET /invoice-lines']['query'],
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /invoice-lines']>();

  return useQuery({
    queryKey: ['invoice-lines', params],
    queryFn: () =>
      fetch('/invoice-lines', params).then(({ invoiceLines, meta }) => ({
        invoiceLines: invoiceLines.map(instanciateInvoiceLineWithRelations),
        meta,
      })),
    enabled: options?.enabled ?? true,
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
  });
};

export const useInvoiceLineName = (
  invoiceLine: Parameters<ReturnType<typeof useGetInvoiceLineName>>[0]
) => {
  const getInvoiceLineName = useGetInvoiceLineName();

  return getInvoiceLineName(invoiceLine);
};

export const useGetInvoiceLineName = () => {
  const { _db } = useDatabaseTranslation();

  return (invoiceLine: {
    price: {
      articleAction?: {
        actionTypeOrganization?: {
          actionType: {
            name: Translation;
          };
        } | null;
        packActionTypeOrganization?: {
          name: Translation;
        } | null;
        customDescription?: string | null;
      } | null;
      fee?: {
        feeType: {
          name: Translation;
        };
      } | null;
    };
  }) => {
    if (invoiceLine.price.articleAction) {
      if (invoiceLine.price.articleAction.actionTypeOrganization) {
        return _db(invoiceLine.price.articleAction.actionTypeOrganization.actionType.name);
      } else if (invoiceLine.price.articleAction.packActionTypeOrganization) {
        return _db(invoiceLine.price.articleAction.packActionTypeOrganization.name);
      } else {
        return invoiceLine.price.articleAction.customDescription ?? '';
      }
    } else if (invoiceLine.price.fee) {
      return _db(invoiceLine.price.fee.feeType.name);
    } else {
      return '';
    }
  };
};
