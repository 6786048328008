import { useEffect, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';

import { FiltersButton, FiltersDrawer } from '@/components/ListFilters';
import { InputDateRange } from '@/design_system/InputDate';
import { InputDateRangeValue } from '@/design_system/InputDate/InputDateRange';
import Stack from '@/design_system/Stack';
import { InvoiceStatus } from '@/models/invoice';
import useViewPort from '@/utils/useViewport';

import { InvoiceDestination, InvoiceDestinationSelect } from './filters/InvoiceDestinationSelect';
import { InvoiceOrigin, InvoiceOriginSelect } from './filters/InvoiceOriginSelect';
import { InvoiceSearch } from './filters/InvoiceSearch';
import { InvoiceStatusSelect } from './filters/InvoiceStatusSelect';

export const InvoicesFilter = ({
  setPage,
  search,
  debouncedSetSearch,
  invoiceOrigin,
  setInvoiceOrigin,
  invoiceDestination,
  setInvoiceDestination,
  invoiceStatus,
  setInvoiceStatus,
  dateRange,
  setDateRange,
}: {
  setPage: (page: number) => void;
  search: string;
  debouncedSetSearch: (value: string) => void;
  invoiceOrigin: InvoiceOrigin;
  setInvoiceOrigin: (invoiceOrigin: InvoiceOrigin) => void;
  invoiceDestination: InvoiceDestination;
  setInvoiceDestination: (invoiceDestination: InvoiceDestination) => void;
  invoiceStatus: InvoiceStatus | '';
  setInvoiceStatus: (invoiceStatus: InvoiceStatus | '') => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const { isMobile } = useViewPort();

  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);

  const hasActiveFilters =
    invoiceOrigin.type !== 'all' ||
    invoiceDestination.type !== 'all' ||
    invoiceStatus !== '' ||
    dateRange.option !== 'any';

  return (
    <ErrorBoundary>
      <Stack row gap="1rem">
        <InvoiceSearch search={search} debouncedSetSearch={debouncedSetSearch} />

        {!isMobile && (
          <>
            <InvoiceOriginSelect
              invoiceOrigin={invoiceOrigin}
              setInvoiceOrigin={(invoiceOrigin) => {
                setInvoiceOrigin(invoiceOrigin);
                setPage(1);
              }}
              style={{ flex: 1, minWidth: '200px', maxWidth: '300px' }}
            />
            <InvoiceDestinationSelect
              invoiceDestination={invoiceDestination}
              setInvoiceDestination={(invoiceDestination) => {
                setInvoiceDestination(invoiceDestination);
                setPage(1);
              }}
              style={{ flex: 1, minWidth: '200px', maxWidth: '300px' }}
            />
            <InvoiceStatusSelect
              setPage={setPage}
              invoiceStatus={invoiceStatus}
              setInvoiceStatus={setInvoiceStatus}
              style={{ flex: 1, minWidth: '200px', maxWidth: '300px' }}
            />
            <InputDateRange
              value={dateRange}
              onChange={(newRange) => {
                setDateRange(newRange);
                setPage(1);
              }}
              style={{ flex: 1, minWidth: '200px', maxWidth: '300px' }}
            />
          </>
        )}

        {isMobile && (
          <>
            <FiltersButton
              hasActiveFilters={hasActiveFilters}
              isFiltersDrawerOpen={isFiltersDrawerOpen}
              setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
            />
            <MobileFilters
              isOpen={isFiltersDrawerOpen}
              onOpenChange={setIsFiltersDrawerOpen}
              setPage={setPage}
              invoiceOrigin={invoiceOrigin}
              setInvoiceOrigin={setInvoiceOrigin}
              invoiceDestination={invoiceDestination}
              setInvoiceDestination={setInvoiceDestination}
              invoiceStatus={invoiceStatus}
              setInvoiceStatus={setInvoiceStatus}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </>
        )}
      </Stack>
    </ErrorBoundary>
  );
};

const MobileFilters = ({
  isOpen,
  onOpenChange,
  setPage,
  invoiceOrigin,
  setInvoiceOrigin,
  invoiceDestination,
  setInvoiceDestination,
  invoiceStatus,
  setInvoiceStatus,
  dateRange,
  setDateRange,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  setPage: (page: number) => void;
  invoiceOrigin: InvoiceOrigin;
  setInvoiceOrigin: (invoiceOrigin: InvoiceOrigin) => void;
  invoiceDestination: InvoiceDestination;
  setInvoiceDestination: (invoiceDestination: InvoiceDestination) => void;
  invoiceStatus: InvoiceStatus | '';
  setInvoiceStatus: (invoiceStatus: InvoiceStatus | '') => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const [newInvoiceOrigin, setNewInvoiceOrigin] = useState(invoiceOrigin);
  const [newInvoiceDestination, setNewInvoiceDestination] = useState(invoiceDestination);
  const [newInvoiceStatus, setNewInvoiceStatus] = useState(invoiceStatus);
  const [newDateRange, setNewDateRange] = useState(dateRange);

  const handleClearFilters = () => {
    setNewInvoiceOrigin({ type: 'all' });
    setNewInvoiceDestination({ type: 'all' });
    setNewInvoiceStatus('');
    setNewDateRange({ option: 'any' });
  };

  const handleApplyFilters = () => {
    const hasInvoiceOriginBeenUpdated =
      invoiceOrigin.type !== newInvoiceOrigin.type || invoiceOrigin.id !== newInvoiceOrigin.id;
    const hasInvoiceDestinationBeenUpdated =
      invoiceDestination.type !== newInvoiceDestination.type ||
      invoiceDestination.id !== newInvoiceDestination.id;
    const hasInvoiceStatusBeenUpdated = invoiceStatus !== newInvoiceStatus;
    const hasDateRangeBeenUpdated =
      dateRange.option !== newDateRange.option ||
      dateRange.start !== newDateRange.start ||
      dateRange.end !== newDateRange.end;

    if (hasInvoiceOriginBeenUpdated) {
      setInvoiceOrigin(newInvoiceOrigin);
    }

    if (hasInvoiceDestinationBeenUpdated) {
      setInvoiceDestination(newInvoiceDestination);
    }

    if (hasInvoiceStatusBeenUpdated) {
      setInvoiceStatus(newInvoiceStatus);
    }

    if (hasDateRangeBeenUpdated) {
      setDateRange(newDateRange);
    }

    if (
      hasInvoiceOriginBeenUpdated ||
      hasInvoiceDestinationBeenUpdated ||
      hasInvoiceStatusBeenUpdated ||
      hasDateRangeBeenUpdated
    ) {
      setPage(1);
    }

    onOpenChange(false);
  };

  // Those useEffect aim to fill the local state of the drawer with the already applied filters
  useEffect(() => {
    setNewInvoiceOrigin(invoiceOrigin);
  }, [invoiceOrigin, isOpen]);

  useEffect(() => {
    setNewInvoiceDestination(invoiceDestination);
  }, [invoiceDestination, isOpen]);

  useEffect(() => {
    setNewInvoiceStatus(invoiceStatus);
  }, [invoiceStatus, isOpen]);

  useEffect(() => {
    setNewDateRange(dateRange);
  }, [dateRange, isOpen]);

  return (
    <FiltersDrawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <InvoiceOriginSelect
        invoiceOrigin={newInvoiceOrigin}
        setInvoiceOrigin={(invoiceOrigin) => {
          setNewInvoiceOrigin(invoiceOrigin);
        }}
        style={{ flex: 1 }}
      />
      <InvoiceDestinationSelect
        invoiceDestination={newInvoiceDestination}
        setInvoiceDestination={(invoiceDestination) => {
          setNewInvoiceDestination(invoiceDestination);
        }}
        style={{ flex: 1 }}
      />
      <InvoiceStatusSelect
        invoiceStatus={newInvoiceStatus}
        setInvoiceStatus={setNewInvoiceStatus}
        style={{ flex: 1 }}
      />
      <InputDateRange value={newDateRange} onChange={setNewDateRange} style={{ flex: 1 }} />
    </FiltersDrawer>
  );
};
