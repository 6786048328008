import { Trans } from '@lingui/react/macro';

import AlertBar from '@/design_system/AlertBar';

import { useInvoiceContext } from '../Invoice';

export const InvoiceIssue = () => {
  const { invoice } = useInvoiceContext();

  if (!invoice.issue) {
    return;
  }

  return (
    <AlertBar
      size="large"
      type="error"
      title={<Trans id="invoice.form.issue">An issue has been reported on this invoice.</Trans>}
    >
      <p style={{ whiteSpace: 'pre-wrap' }}>&quot;{invoice.issue}&quot;</p>
    </AlertBar>
  );
};
