import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Trans } from '@lingui/react/macro';

import { ArticleStepper } from '@/components/ArticleStepper/ArticleStepper';
import Loader from '@/components/Loader';
import {
  PageLayout,
  PageLayoutCenter,
  PageLayoutContent,
  PageLayoutHeader,
  PageLayoutLeftPart,
  PageLayoutLeftPartContent,
  PageLayoutRightPart,
  PageLayoutTopPart,
} from '@/components/PageLayout';
import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconEdit from '@/icons/Edit.svg';
import IconPlace from '@/icons/Place.svg';
import { useArticleName } from '@/models/article';
import { useRequest } from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import { ArticleServices } from '@/routes/Requests/components/ArticleServices/ArticleServices';
import {
  ArticleContextProvider,
  useArticleContext,
} from '@/routes/Requests/contexts/ArticleContext';
import { RequestContextProvider } from '@/routes/Requests/contexts/RequestContext';
import ClientComment from '@/routes/Requests/Request/Article/ClientComment';
import RequalificationComment from '@/routes/Requests/Request/Article/RequalificationComment';
import Warranty from '@/routes/Requests/Request/Article/Warranty';
import { ArticleActivities } from '@/routes/Requests/Request/components/Activities/ArticleActivities';
import { RequestBreadcrumb } from '@/routes/Requests/Request/components/RequestBreadcrumb/RequestBreadcrumb';
import { RequestComments } from '@/routes/Requests/Request/components/RequestComments';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { useResetSimpleBlocker } from '@/utils/navigation';
import { refreshBrowserTabTitle } from '@/utils/refreshBrowserTabTitle';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import ArticleActions from './components/ArticleActions';
import { ArticleInfoSidePanel } from './components/ArticleInfo/ArticleInfoSidePanel';
import { ArticleInfoTopPanel } from './components/ArticleInfo/ArticleInfoTopPanel';
import ArticleTaskOrStepMessage from './components/ArticleTaskOrStepMessage';
import { ArticleHeader } from './ArticleHeader';

import './Article.css';

const { block, element } = createBEMClasses('article');

export const Article = () => {
  const navigate = useNavigate();
  const { isMobile } = useViewPort();
  const { requestId, articleId } = useParams();
  const { data: request, isLoading: isRequestLoading } = useRequest(requestId);
  const { data: workflow, isLoading: isWorkflowLoading } = useWorkflow(request?.workflowId);
  const { currentSession } = useCurrentSession();
  const [commentsRef, scrollCommentsIntoView] = useScrollIntoView<HTMLDivElement>();
  const article = request?.allArticles.find(({ id }) => id === articleId);
  const [containerRef, scrollContainerIntoView] = useScrollIntoView<HTMLDivElement>();

  const articleName = useArticleName({ article });

  // Display the request reference in the browser tab title
  useEffect(() => {
    if (!request) {
      return;
    }

    const requestDetails = request.client?.name ?? request.store?.name;
    const requestReference = requestDetails
      ? `${request.reference} - ${articleName}`
      : request.reference;

    document.querySelector('meta[name="subtitle"]')?.setAttribute('content', requestReference);
    refreshBrowserTabTitle();

    return () => {
      document.querySelector('meta[name="subtitle"]')?.setAttribute('content', '');
      refreshBrowserTabTitle();
    };
  }, [request, articleName]);

  // Clear router blocker state after coming from requests/new
  useResetSimpleBlocker();

  const isOrganizationUser = currentSession?.organizations?.some(
    (organization) => organization.id === article?.organizationId
  );

  if (isRequestLoading || isWorkflowLoading) {
    return (
      <PageLayoutCenter>
        <Loader style={{ height: '40px', width: '40px' }} />
        <p className="paragraph-100-regular">
          <Trans id="_general.loading">Loading...</Trans>
        </p>
      </PageLayoutCenter>
    );
  }

  if (!request || !article) {
    return (
      <PageLayoutCenter>
        <p className="paragraph-100-regular">
          <Trans id="article.unknown">Unknown article</Trans>
        </p>
      </PageLayoutCenter>
    );
  }

  return (
    <RequestContextProvider request={request} workflow={workflow}>
      <ArticleContextProvider article={article}>
        <PageLayout className={block()}>
          <PageLayoutHeader>
            <ArticleHeader
              request={request}
              article={article}
              onCommentButtonPress={scrollCommentsIntoView}
            />
          </PageLayoutHeader>

          <PageLayoutContent>
            <PageLayoutLeftPart>
              <PageLayoutLeftPartContent>
                {isMobile && (
                  <PageLayoutTopPart>
                    <CustomsAlertBar />
                    <ArticleInfoTopPanel />
                  </PageLayoutTopPart>
                )}

                {!isMobile && (
                  <>
                    <CustomsAlertBar />
                    <RequestBreadcrumb
                      request={request}
                      article={article}
                      onCommentButtonPress={scrollCommentsIntoView}
                    />
                  </>
                )}

                <Stack className={element('main')} ref={containerRef}>
                  <Stack gap="1rem">
                    <ArticleStepper
                      size={isMobile ? 'small' : 'large'}
                      showStepCount={isMobile}
                      article={article}
                      mode="step"
                      labelVariant={undefined}
                    />
                    <ArticleTaskOrStepMessage article={article} request={request} />
                  </Stack>

                  <Stack gap="2rem">
                    {article.task?.type === 'analyze_article' && (
                      <RequalificationComment article={article} />
                    )}

                    <Warranty />

                    <Stack gap="0.5rem">
                      <h2 className="headline-400-bold">
                        <Trans id="article.services">Services</Trans>
                      </h2>

                      <Box className={element('services')}>
                        <ArticleServices />
                      </Box>
                    </Stack>

                    {isOrganizationUser && request.requestorType !== 'store' && (
                      <Stack gap="0.5rem">
                        <h2 className="headline-400-bold">
                          <Trans id="article.client-communication">
                            Communication to the client
                          </Trans>
                        </h2>
                        <Box className={element('client-communication')}>
                          <ClientComment
                            request={request}
                            article={article}
                            disabled={article.task?.type !== 'choose_article_service'}
                            key={`client-comment ${article.clientComment ?? ''}`}
                          />
                        </Box>
                      </Stack>
                    )}

                    <Stack gap={isMobile ? undefined : '0.5rem'} ref={commentsRef}>
                      <h2 className="headline-400-bold">
                        <Trans id="article.comment.title">Item comments</Trans>
                      </h2>
                      <ErrorBoundary>
                        <Box
                          className={element('comment', { 'no-background': isMobile })}
                          padding="0"
                          removeStyleOnMobile
                        >
                          <RequestComments requestId={request.id} articleId={article.id} />
                        </Box>
                      </ErrorBoundary>
                    </Stack>

                    <Stack gap={isMobile ? undefined : '0.5rem'} ref={null}>
                      <h2 className="headline-400-bold">
                        <Trans id="article.activity.title">Activity</Trans>
                      </h2>
                      <ErrorBoundary>
                        <Box
                          className={element('activity', { 'no-background': isMobile })}
                          padding="0"
                          removeStyleOnMobile
                        >
                          <ArticleActivities articleId={article.id} />
                        </Box>
                      </ErrorBoundary>
                    </Stack>
                  </Stack>
                </Stack>
              </PageLayoutLeftPartContent>

              <ArticleActions
                article={article}
                request={request}
                onActionDone={(options?: { navigateToRequests?: boolean }) => {
                  if (options?.navigateToRequests) {
                    navigate('/requests');
                  } else if (request.articles.length > 1) {
                    navigate(`/requests/${request.id}`);
                  } else {
                    scrollContainerIntoView();
                  }
                }}
              />
            </PageLayoutLeftPart>

            {!isMobile && (
              <PageLayoutRightPart>
                <ArticleInfoSidePanel />
              </PageLayoutRightPart>
            )}
          </PageLayoutContent>
        </PageLayout>
      </ArticleContextProvider>
    </RequestContextProvider>
  );
};

const CustomsAlertBar = () => {
  const { currentSession } = useCurrentSession();
  const { article, state } = useArticleContext();

  if (
    article.areCustomsExpected &&
    !article.hasRequiredCustomsInfo &&
    currentSession!.hasPermission('edit_article', {
      organizationId: article.organizationId,
    })
  ) {
    const isError = article.step?.step === 'service-choice';

    return (
      <AlertBar type={isError ? 'error' : 'warning'} size="large" icon={<IconPlace warning />}>
        <Stack gap="0.5rem">
          <p>
            <Trans id="article.customs.missing-info">
              The item needs to go through customs. Please complete the missing information about
              the product to be allowed through.
            </Trans>
          </p>
          <div>
            <Button variant="secondary" onPress={() => state.infoPanel.setIsOpen(true)}>
              <IconEdit />
              <Trans id="article.customs.missing-info.action">Edit information</Trans>
            </Button>
          </div>
        </Stack>
      </AlertBar>
    );
  }

  return null;
};
