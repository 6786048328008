import { CSSProperties, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { Trans } from '@lingui/react/macro';

import { FileViewer } from '@/design_system/FileViewer/FileViewer';
import { useShowToast } from '@/design_system/Toast';
import { CreateMediumBody, Medium, useCreateMedium, useDeleteMedium } from '@/models/medium';

export type FileViewerUploadProps = {
  prompt?: ReactNode;
  media?: Medium[];
  onChange?: Dispatch<SetStateAction<Medium[]>>;
  onUpload?: (medium: Medium) => void;
  onDelete?: (mediumId: string) => void;
  uploadData: CreateMediumBody;
  deleteWithApi?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  style?: CSSProperties;
};

export const FileViewerUpload = ({
  prompt,
  media = [],
  onChange,
  onUpload,
  onDelete,
  uploadData,
  deleteWithApi,
  isDisabled,
  isInvalid,
}: FileViewerUploadProps) => {
  const showToast = useShowToast();

  const { mutateAsync: createMedium } = useCreateMedium();
  const { mutateAsync: deleteMedium } = useDeleteMedium();

  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async (files: File[]) => {
    setIsUploading(true);

    await Promise.all(
      files.map(async (file) => {
        try {
          const medium = await createMedium({ file, ...uploadData });
          onChange?.((prev) => [medium, ...prev]);
          onUpload?.(medium);
        } catch (e) {
          console.error('Error uploading', file.name, ':', e);
          const fileName = file.name;
          showToast({
            text: (
              <Trans id="file-viewer-upload.error">
                An error occurred while uploading {fileName}
              </Trans>
            ),
            type: 'error',
          });
        }
      })
    );

    setIsUploading(false);
  };

  const handleDelete = async (idToDelete: string) => {
    if (deleteWithApi) {
      await deleteMedium(idToDelete);
    }

    onChange?.((prev) => prev.filter(({ id }) => id !== idToDelete));
    onDelete?.(idToDelete);
  };

  return (
    <FileViewer
      files={media.map((medium) => ({
        id: medium.id,
        url: medium.url,
        type: medium.isImage ? 'image' : 'pdf',
      }))}
      prompt={prompt}
      onFileSelect={handleUpload}
      onDelete={handleDelete}
      isUploading={isUploading}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    />
  );
};
