import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import RadioGroup from '@/design_system/RadioGroup';
import Stack from '@/design_system/Stack';
import { useShowToast } from '@/design_system/Toast';
import { useRepair } from '@/models/article';
import { InvoicePaymentStrategy } from '@/models/invoice';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';

import { ArticleActionProps } from './ArticleActions';

export const RepairArticleAction = ({ article, onActionDone }: ArticleActionProps) => {
  const { t } = useLingui();
  const showToast = useShowToast();

  const {
    mutateAsync: repair,
    isPending: isPendingRepair,
    isSuccess: isSuccessRepair,
  } = useRepair({
    articleId: article.id,
  });

  const [isPaymentStrategyModalOpen, setIsPaymentStrategyModalOpen] = useState(false);

  const confirmRepair = async ({
    paymentStrategy,
  }: {
    paymentStrategy?: InvoicePaymentStrategy;
  }) => {
    try {
      await repair({
        paymentStrategy,
      });
      onActionDone();
    } catch {
      showToast({
        type: 'error',
        text: t({
          id: 'article.actions.repair_article.error',
          message:
            'An error occurred while marking the article as repaired. Please try again later.',
        }),
      });
    } finally {
      setIsPaymentStrategyModalOpen(false);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        size="medium"
        onPress={() => {
          if (article.workshop?.nonDigitalized && !article.workshopPaymentStrategy) {
            setIsPaymentStrategyModalOpen(true);
          } else {
            confirmRepair({});
          }
        }}
        isLoading={(isPendingRepair || isSuccessRepair) && !isPaymentStrategyModalOpen}
      >
        <Trans id="article.actions.repair_article.validate">Mark as repaired</Trans>
      </Button>
      <WorkshopPaymentStrategy
        isOpen={isPaymentStrategyModalOpen}
        onClose={() => setIsPaymentStrategyModalOpen(false)}
        onConfirm={confirmRepair}
        isLoading={isPendingRepair}
      />
    </>
  );
};

const WorkshopPaymentStrategy = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (data: { paymentStrategy: InvoicePaymentStrategy }) => void;
  isLoading: boolean;
}) => {
  const { article } = useArticleContext();
  const { t } = useLingui();

  const [paymentStrategy, setPaymentStrategy] = useState(article.workshopPaymentStrategy);
  const [showError, setShowError] = useState(false);

  const options: { value: InvoicePaymentStrategy; text: string; subText: string }[] = [
    {
      value: 'store-cash-out',
      text: t({
        id: 'article.actions.repair_article.workshop_payment_strategy.payment_strategy.store_cash_out.text',
        message: 'The store',
      }),
      subText: t({
        id: 'article.actions.repair_article.workshop_payment_strategy.payment_strategy.store_cash_out.sub_text',
        message: 'Via a cash register operation',
      }),
    },
    {
      value: 'handled-by-accounting-team',
      text: t({
        id: 'article.actions.repair_article.workshop_payment_strategy.payment_strategy.handled_by_accounting_team.text',
        message: 'The accounting department',
      }),
      subText: t({
        id: 'article.actions.repair_article.workshop_payment_strategy.payment_strategy.handled_by_accounting_team.sub_text',
        message: 'Via bank transfer at the end of the month',
      }),
    },
  ];

  return (
    <Dialog
      isOpen={isOpen}
      title={
        <Trans id="article.actions.repair_article.workshop_payment_strategy.title">
          Payment to workshop information
        </Trans>
      }
      onOpenChange={onClose}
    >
      <main>
        <RadioGroup
          label={
            <Trans id="article.actions.repair_article.workshop_payment_strategy.payment_strategy.label">
              Who is responsible for making the payment?
            </Trans>
          }
          options={options}
          value={paymentStrategy}
          onChange={(value) => setPaymentStrategy(value as InvoicePaymentStrategy)}
        />
      </main>
      <footer>
        <Stack row alignItems="center" justifyContent="space-between" flexWrap="nowrap">
          {showError && (
            <span className="paragraph-100-regular text-danger">
              <Trans id="article.actions.repair_article.workshop_payment_strategy.error">
                Please select the entity responsible for making the payment
              </Trans>
            </span>
          )}
          <Button
            variant="primary"
            size="medium"
            onPress={() => {
              if (!paymentStrategy) {
                setShowError(true);
                return;
              }

              onConfirm({ paymentStrategy });
            }}
            isLoading={isLoading}
          >
            <span style={{ textWrap: 'nowrap' }}>
              <Trans id="article.actions.repair_article.workshop_payment_strategy.confirm">
                Confirm and validate repair
              </Trans>
            </span>
          </Button>
        </Stack>
      </footer>
    </Dialog>
  );
};
