import { Plural, Trans, useLingui } from '@lingui/react/macro';

import IconInvoice from '@/icons/Invoice.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const InvoicesToValidateNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'invoices_to_validate'>;
}) => {
  const { t } = useLingui();

  const { nbInvoicesToValidate } = notification.data;

  return (
    <BaseNotification
      notificationLabel={t({
        id: 'notification.invoices-to-validate.label',
        message: 'You have invoices to validate',
      })}
      icon={<IconInvoice />}
      type="warning"
      notification={notification}
      notificationHeader={
        <Trans id="notification.invoices-to-validate">
          <span className="paragraph-100-medium">You</span> have {nbInvoicesToValidate}{' '}
          <Plural value={nbInvoicesToValidate} one="invoice" other="invoices" /> to validate
        </Trans>
      }
      notificationHref="/accounting?tab=invoices&invoiceStatus=pending-validation"
    />
  );
};
