import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import { InputMoney } from '@/design_system/InputNumber';
import { useUpdateInvoice } from '@/models/invoice';

import { useInvoiceContext } from '../Invoice';

export const InvoiceAmount = () => {
  const { t } = useLingui();
  const { invoice, showErrors, errors, isDisabled } = useInvoiceContext();
  const { mutate: updateInvoice } = useUpdateInvoice(invoice.id);

  const isInputDisabled = isDisabled || !invoice.currency;
  const [amount, setAmount] = useState(invoice.amount);

  const showAmountMismatchAlert =
    !!invoice.lines.length &&
    !!invoice.amount &&
    invoice.lines.reduce((acc, line) => acc + line.price.amount, 0) !== invoice.amount;

  return (
    <InputMoney
      label={<Trans id="invoice.form.amount">Invoice total amount</Trans>}
      placeholder={
        isInputDisabled
          ? '-'
          : t({
              id: 'invoice.form.amount.placeholder',
              message: 'Enter the invoice total amount...',
            })
      }
      currency={invoice.currency ?? 'EUR'}
      value={amount ?? undefined}
      onChange={(newAmount) => {
        setAmount(newAmount);

        if (newAmount !== invoice.amount) {
          updateInvoice({
            amount: isNaN(newAmount) ? null : newAmount,
          });
        }
      }}
      isDisabled={isInputDisabled}
      isInvalid={showErrors && errors.amount}
      {...(showAmountMismatchAlert
        ? {
            messageText: t({
              id: 'invoice.form.amount.mismatch',
              message: 'The amount does not match the total services amount',
            }),
            messageType: 'warning',
          }
        : undefined)}
    />
  );
};
