import { useEffect, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';

import { FiltersButton, FiltersDrawer } from '@/components/ListFilters';
import { InputDateRange } from '@/design_system/InputDate';
import { InputDateRangeValue } from '@/design_system/InputDate/InputDateRange';
import Stack from '@/design_system/Stack';
import useViewPort from '@/utils/useViewport';

import {
  TransactionDestination,
  TransactionDestinationSelect,
} from './filters/TransactionDestinationSelect';
import { TransactionOrigin, TransactionOriginSelect } from './filters/TransactionOriginSelect';
import { TransactionSearch } from './filters/TransactionSearch';

export const TransactionsFilter = ({
  setPage,
  search,
  debouncedSetSearch,
  transactionOrigin,
  setTransactionOrigin,
  transactionDestination,
  setTransactionDestination,
  dateRange,
  setDateRange,
}: {
  setPage: (page: number) => void;
  search: string;
  debouncedSetSearch: (value: string) => void;
  transactionOrigin: TransactionOrigin;
  setTransactionOrigin: (transactionOrigin: TransactionOrigin) => void;
  transactionDestination: TransactionDestination;
  setTransactionDestination: (transactionDestination: TransactionDestination) => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const { isMobile } = useViewPort();

  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);

  const hasActiveFilters =
    transactionOrigin.type !== 'all' ||
    transactionDestination.type !== 'all' ||
    dateRange.option !== 'any';

  return (
    <ErrorBoundary>
      <Stack row gap="1rem">
        <TransactionSearch search={search} debouncedSetSearch={debouncedSetSearch} />

        {!isMobile && (
          <>
            <TransactionOriginSelect
              setPage={setPage}
              transactionOrigin={transactionOrigin}
              setTransactionOrigin={setTransactionOrigin}
              style={{ flex: 1, minWidth: '200px', maxWidth: '300px' }}
            />
            <TransactionDestinationSelect
              setPage={setPage}
              transactionDestination={transactionDestination}
              setTransactionDestination={setTransactionDestination}
              style={{ flex: 1, minWidth: '200px', maxWidth: '300px' }}
            />
            <InputDateRange
              value={dateRange}
              onChange={(newRange) => {
                setDateRange(newRange);
                setPage(1);
              }}
              style={{ flex: 1, minWidth: '200px', maxWidth: '300px' }}
            />
          </>
        )}

        {isMobile && (
          <>
            <FiltersButton
              hasActiveFilters={hasActiveFilters}
              isFiltersDrawerOpen={isFiltersDrawerOpen}
              setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
            />
            <MobileFilters
              isOpen={isFiltersDrawerOpen}
              onOpenChange={setIsFiltersDrawerOpen}
              setPage={setPage}
              transactionOrigin={transactionOrigin}
              setTransactionOrigin={setTransactionOrigin}
              transactionDestination={transactionDestination}
              setTransactionDestination={setTransactionDestination}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </>
        )}
      </Stack>
    </ErrorBoundary>
  );
};

const MobileFilters = ({
  isOpen,
  onOpenChange,
  setPage,
  transactionOrigin,
  setTransactionOrigin,
  transactionDestination,
  setTransactionDestination,
  dateRange,
  setDateRange,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  setPage: (page: number) => void;
  transactionOrigin: TransactionOrigin;
  setTransactionOrigin: (transactionOrigin: TransactionOrigin) => void;
  transactionDestination: TransactionDestination;
  setTransactionDestination: (transactionDestination: TransactionDestination) => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const [newTransactionOrigin, setNewTransactionOrigin] = useState(transactionOrigin);
  const [newTransactionDestination, setNewTransactionDestination] =
    useState(transactionDestination);
  const [newDateRange, setNewDateRange] = useState(dateRange);

  const handleClearFilters = () => {
    setNewTransactionOrigin({ type: 'all' });
    setNewTransactionDestination({ type: 'all' });
    setNewDateRange({ option: 'any' });
  };

  const handleApplyFilters = () => {
    const hasTransactionOriginBeenUpdated =
      transactionOrigin.type !== newTransactionOrigin.type ||
      transactionOrigin.id !== newTransactionOrigin.id;
    const hasTransactionDestinationBeenUpdated =
      transactionDestination.type !== newTransactionDestination.type ||
      transactionDestination.id !== newTransactionDestination.id;
    const hasDateRangeBeenUpdated =
      dateRange.option !== newDateRange.option ||
      dateRange.start !== newDateRange.start ||
      dateRange.end !== newDateRange.end;

    if (hasTransactionOriginBeenUpdated) {
      setTransactionOrigin(newTransactionOrigin);
    }

    if (hasTransactionDestinationBeenUpdated) {
      setTransactionDestination(newTransactionDestination);
    }

    if (hasDateRangeBeenUpdated) {
      setDateRange(newDateRange);
    }

    if (
      hasTransactionOriginBeenUpdated ||
      hasTransactionDestinationBeenUpdated ||
      hasDateRangeBeenUpdated
    ) {
      setPage(1);
    }

    onOpenChange(false);
  };

  // Those useEffect aim to fill the local state of the drawer with the already applied filters
  useEffect(() => {
    setNewTransactionOrigin(transactionOrigin);
  }, [transactionOrigin, isOpen]);

  useEffect(() => {
    setNewTransactionDestination(transactionDestination);
  }, [transactionDestination, isOpen]);

  useEffect(() => {
    setNewDateRange(dateRange);
  }, [dateRange, isOpen]);

  return (
    <FiltersDrawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <TransactionOriginSelect
        transactionOrigin={newTransactionOrigin}
        setTransactionOrigin={setNewTransactionOrigin}
        style={{ flex: 1 }}
      />
      <TransactionDestinationSelect
        transactionDestination={newTransactionDestination}
        setTransactionDestination={setNewTransactionDestination}
        style={{ flex: 1 }}
      />
      <InputDateRange value={newDateRange} onChange={setNewDateRange} style={{ flex: 1 }} />
    </FiltersDrawer>
  );
};
