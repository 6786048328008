import { Trans, useLingui } from '@lingui/react/macro';

import IconAnalysis from '@/icons/Analysis.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const DispatchProposalSubmittedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'dispatch_proposal_submitted'>;
}) => {
  const { t } = useLingui();
  const workshopName = notification.data.workshopName;
  const reference = notification.request.reference;

  return (
    <BaseNotification
      notificationLabel={t({
        id: 'notification.dispatch_proposal_submitted.label',
        message: `${workshopName} accepted the job for request ${reference} and proposed a new quote`,
      })}
      icon={<IconAnalysis />}
      type="new"
      notification={notification}
      notificationHeader={
        <Trans id="notification.dispatch_proposal_submitted">
          <span className="paragraph-100-medium">{workshopName}</span> accepted the job and proposed
          a new quote
        </Trans>
      }
    />
  );
};
