import { GridList, GridListItem } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';
import { parseISO } from 'date-fns';

import { TableQueryWrapper } from '@/components/TableQueryWrapper';
import config from '@/config';
import Badge from '@/design_system/Badge';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import {
  InputDateRangeValue,
  isoStringToDateString,
} from '@/design_system/InputDate/InputDateRange';
import Pagination, { PaginationFooter } from '@/design_system/Pagination/Pagination';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconDone from '@/icons/Done.svg';
import IconDownload from '@/icons/Download.svg';
import IconError from '@/icons/Error.svg';
import IconTools from '@/icons/Tools.svg';
import IconTransaction from '@/icons/Transaction.svg';
import IconValidation from '@/icons/Validation.svg';
import IconValidationWaiting from '@/icons/ValidationWaiting.svg';
import { InvoiceStatus, InvoiceWithRelations, useInvoices } from '@/models/invoice';
import { formatDate } from '@/utils/date';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { InvoiceDestination } from './components/filters/InvoiceDestinationSelect';
import { InvoiceOrigin } from './components/filters/InvoiceOriginSelect';
import { InvoicesEmptyState } from './components/InvoicesEmptyState';
import { InvoicesFilter } from './components/InvoicesFilter';

export const INVOICES_PER_PAGE = 20;

export const Invoices = ({
  page,
  setPage,
  search,
  debouncedSearch,
  debouncedSetSearch,
  invoiceStatus,
  setInvoiceStatus,
  invoiceOrigin,
  setInvoiceOrigin,
  invoiceDestination,
  setInvoiceDestination,
  dateRange,
  setDateRange,
}: {
  page: number;
  setPage: (page: number) => void;
  search: string;
  debouncedSearch: string;
  debouncedSetSearch: (value: string) => void;
  invoiceStatus: InvoiceStatus | '';
  setInvoiceStatus: (invoiceStatus: InvoiceStatus | '') => void;
  invoiceOrigin: InvoiceOrigin;
  setInvoiceOrigin: (invoiceOrigin: InvoiceOrigin) => void;
  invoiceDestination: InvoiceDestination;
  setInvoiceDestination: (invoiceDestination: InvoiceDestination) => void;
  dateRange: InputDateRangeValue;
  setDateRange: (dateRange: InputDateRangeValue) => void;
}) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();

  const {
    data: { invoices, meta } = { invoices: [] },
    isLoading,
    isError,
  } = useInvoices({
    limit: INVOICES_PER_PAGE,
    offset: (page - 1) * INVOICES_PER_PAGE,
    search: debouncedSearch || undefined,
    status: invoiceStatus || undefined,
    destinationClient: invoiceDestination.type === 'client',
    destinationOrganizationCountry:
      invoiceDestination.type === 'organization-country' ? invoiceDestination.id : undefined,
    originOrganizationCountry:
      invoiceOrigin.type === 'organization-country' ? invoiceOrigin.id : undefined,
    originWorkshop: invoiceOrigin.type === 'workshop' ? invoiceOrigin.id : undefined,
    issuingDateFrom: dateRange.start ? isoStringToDateString(dateRange.start) : undefined,
    issuingDateTo: dateRange.end ? isoStringToDateString(dateRange.end) : undefined,
  });

  const columnWidths = {
    name: 'minmax(180px, auto)',
    issuer: 'minmax(180px, 1fr)',
    recipient: 'minmax(180px, 1fr)',
    status: 'minmax(180px, 1fr)',
    dueDate: 'minmax(100px, auto)',
    amount: 'minmax(100px, auto)',
    actions: 'minmax(100px, auto)',
  };

  return (
    <Stack gap="1rem" style={{ height: '100%' }}>
      <InvoicesFilter
        setPage={setPage}
        search={search}
        debouncedSetSearch={debouncedSetSearch}
        invoiceOrigin={invoiceOrigin}
        setInvoiceOrigin={setInvoiceOrigin}
        invoiceDestination={invoiceDestination}
        setInvoiceDestination={setInvoiceDestination}
        invoiceStatus={invoiceStatus}
        setInvoiceStatus={setInvoiceStatus}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      <TableQueryWrapper isLoading={isLoading} isError={isError}>
        {!invoices.length ? (
          <InvoicesEmptyState />
        ) : (
          <>
            {!isMobile && (
              <Table
                ariaLabel={t({
                  id: 'accounting.invoices.table.label',
                  message: 'Invoices list',
                })}
                columnWidths={[
                  columnWidths.name,
                  columnWidths.issuer,
                  columnWidths.recipient,
                  columnWidths.status,
                  columnWidths.dueDate,
                  columnWidths.amount,
                  columnWidths.actions,
                ]}
              >
                <Header>
                  <Row>
                    <Column>
                      <Trans id="accounting.invoices.table.column.number">Number</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoices.table.column.issuer">Issuer</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoices.table.column.recipient">Recipient</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoices.table.column.status">Status</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoices.table.column.dueDate">Due date</Trans>
                    </Column>
                    <Column>
                      <Trans id="accounting.invoices.table.column.amount">Services amount</Trans>
                    </Column>
                    <Column
                      ariaLabel={t({
                        id: 'accounting.invoices.table.column.actions',
                        message: 'Actions',
                      })}
                    />
                  </Row>
                </Header>

                <Body>
                  {invoices.map((invoice) => (
                    <InvoiceRow key={invoice.id} invoice={invoice} />
                  ))}
                </Body>

                <PaginationFooter
                  page={page}
                  itemsPerPage={INVOICES_PER_PAGE}
                  count={meta?.count}
                  onPageChange={setPage}
                />
              </Table>
            )}

            {isMobile && (
              <>
                <GridList
                  aria-label={t({
                    id: 'accounting.invoices.table.label',
                    message: 'Invoices list',
                  })}
                  style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                >
                  {invoices.map((invoice) => (
                    <InvoiceCard key={invoice.id} invoice={invoice} />
                  ))}
                </GridList>

                <Pagination
                  page={page}
                  itemsPerPage={INVOICES_PER_PAGE}
                  count={meta?.count}
                  onPageChange={setPage}
                />
              </>
            )}
          </>
        )}
      </TableQueryWrapper>
    </Stack>
  );
};

const InvoiceRow = ({ invoice }: { invoice: InvoiceWithRelations }) => {
  const issuer = invoice.originOrganizationCountry?.name ?? invoice.originWorkshop?.name;
  const recipient = invoice.destinationOrganizationCountry?.name ?? invoice.destinationClient?.name;

  return (
    <Row to={`/accounting/invoices/${invoice.id}`}>
      <InvoiceNumberCell invoice={invoice} />
      <Cell>{issuer}</Cell>
      <Cell>{recipient}</Cell>
      <Cell>
        <InvoiceStatusBadge invoice={invoice} />
      </Cell>
      <Cell>
        {invoice.paymentDueDate
          ? formatDate(parseISO(invoice.paymentDueDate), {
              dateStyle: 'medium',
            })
          : '-'}
      </Cell>
      <Cell justifyContent="flex-end">{formatCurrency(invoice.amount, invoice.currency)}</Cell>
      <Cell justifyContent="flex-end">
        <Stack row gap="8px">
          {invoice.transactionId && (
            <Button
              to={`/accounting/transactions/${invoice.transactionId}`}
              variant="secondary"
              size="medium"
              iconOnly
              tooltip={
                <Trans id="accounting.invoices.table.tooltip.view-transaction">
                  View transaction
                </Trans>
              }
            >
              <IconTransaction variant="view" />
            </Button>
          )}
          {!!invoice.media.length && (
            <Button
              variant="secondary"
              size="medium"
              iconOnly
              tooltip={
                <Trans id="accounting.invoices.table.tooltip.download-invoice">
                  Download invoice
                </Trans>
              }
              href={`${config.apiUrl}/invoices/${invoice.id}/media`}
              target="_blank"
            >
              <IconDownload />
            </Button>
          )}
        </Stack>
      </Cell>
    </Row>
  );
};

const InvoiceNumberCell = ({ invoice }: { invoice: InvoiceWithRelations }) => {
  return (
    <Cell isLink>
      <Stack>
        <span className="paragraph-100-medium">{invoice.number}</span>
        {invoice.issuingDate && (
          <span className="paragraph-200-regular">
            {formatDate(parseISO(invoice.issuingDate), {
              dateStyle: 'medium',
            })}
          </span>
        )}
      </Stack>
    </Cell>
  );
};

const InvoiceStatusBadge = ({ invoice }: { invoice: InvoiceWithRelations }) => {
  if (invoice.status === 'pending-validation') {
    return (
      <Badge color="warning" size="small">
        <Stack row gap="4px" alignItems="center" flexWrap="nowrap">
          <IconValidationWaiting />
          <Trans id="accounting.invoices.table.status.pending-validation">Pending validation</Trans>
        </Stack>
      </Badge>
    );
  }

  if (invoice.status === 'handled-by-accounting') {
    return (
      <Badge color="tertiary" size="small">
        <Stack row gap="4px" alignItems="center" flexWrap="nowrap">
          <IconValidation />
          <Trans id="accounting.invoices.table.status.handled-by-accounting">
            Handled by accounting
          </Trans>
        </Stack>
      </Badge>
    );
  }

  if (invoice.status === 'paid') {
    return (
      <Badge color="primary" size="small">
        <Stack row gap="4px" alignItems="center" flexWrap="nowrap">
          <IconDone />
          <span>
            <Trans id="accounting.invoices.table.status.paid">Paid</Trans>
            {invoice.paidAt && (
              <>
                {' '}
                <span className="paragraph-400-regular">
                  (
                  {formatDate(new Date(invoice.paidAt), {
                    dateStyle: 'medium',
                  })}
                  )
                </span>
              </>
            )}
          </span>
        </Stack>
      </Badge>
    );
  }

  if (invoice.status === 'issue') {
    return (
      <Badge color="danger" size="small">
        <Stack row gap="4px" alignItems="center" flexWrap="nowrap">
          <IconError />
          <Trans id="accounting.invoices.table.status.issue">Issue reported</Trans>
        </Stack>
      </Badge>
    );
  }

  return null;
};

const InvoiceCard = ({ invoice }: { invoice: InvoiceWithRelations }) => {
  const issuer = invoice.originOrganizationCountry?.name ?? invoice.originWorkshop?.name;
  const recipient = invoice.destinationOrganizationCountry?.name ?? invoice.destinationClient?.name;

  return (
    <GridListItem textValue={invoice.number} href={`/accounting/invoices/${invoice.id}`}>
      <Box padding="16px" role="row" gap="8px">
        <Stack row alignItems="center" justifyContent="space-between">
          <span className="paragraph-100-medium text-secondary">{invoice.number}</span>
          <InvoiceStatusBadge invoice={invoice} />
        </Stack>
        <Stack>
          <span className="paragraph-100-regular">{issuer}</span>
          <span className="paragraph-200-regular text-secondary">
            <Trans id="accounting.invoices.table.recipient.to">To: {recipient}</Trans>
          </span>
        </Stack>
        <div style={{ marginTop: '4px' }}>
          <Badge color="neutral" size="large" borderRadius="soft" hasBorder>
            <Stack row gap="4px">
              <IconTools style={{ fontSize: '1rem' }} />
              <Trans id="accounting.invoices.table.amount">
                Services amount: {formatCurrency(invoice.amount, invoice.currency)}
              </Trans>
            </Stack>
          </Badge>
        </div>
      </Box>
    </GridListItem>
  );
};
