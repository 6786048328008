import IconWrapper, { IconProps } from './IconWrapper';

const IconArrowUp = IconWrapper({
  name: 'arrow',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4697 4.46967C11.7626 4.17678 12.2374 4.17678 12.5303 4.46967L18.0303 9.96967C18.3232 10.2626 18.3232 10.7374 18.0303 11.0303C17.7374 11.3232 17.2626 11.3232 16.9697 11.0303L12.75 6.81066V19C12.75 19.4142 12.4142 19.75 12 19.75C11.5858 19.75 11.25 19.4142 11.25 19V6.81066L7.03033 11.0303C6.73744 11.3232 6.26256 11.3232 5.96967 11.0303C5.67678 10.7374 5.67678 10.2626 5.96967 9.96967L11.4697 4.46967Z"
      />
    </svg>
  ),
});

const IconArrowDown = IconWrapper({
  name: 'arrow',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5303 19.5303C12.2374 19.8232 11.7626 19.8232 11.4697 19.5303L5.96967 14.0303C5.67678 13.7374 5.67678 13.2626 5.96967 12.9697C6.26256 12.6768 6.73744 12.6768 7.03033 12.9697L11.25 17.1893L11.25 5C11.25 4.58579 11.5858 4.25 12 4.25C12.4142 4.25 12.75 4.58579 12.75 5L12.75 17.1893L16.9697 12.9697C17.2626 12.6768 17.7374 12.6768 18.0303 12.9697C18.3232 13.2626 18.3232 13.7374 18.0303 14.0303L12.5303 19.5303Z"
      />
    </svg>
  ),
});

const IconArrowLeft = IconWrapper({
  name: 'arrow',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46967 12.5303C4.17678 12.2374 4.17678 11.7626 4.46967 11.4697L9.96967 5.96967C10.2626 5.67678 10.7374 5.67678 11.0303 5.96967C11.3232 6.26256 11.3232 6.73744 11.0303 7.03033L6.81066 11.25L19 11.25C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75L6.81066 12.75L11.0303 16.9697C11.3232 17.2626 11.3232 17.7374 11.0303 18.0303C10.7374 18.3232 10.2626 18.3232 9.96967 18.0303L4.46967 12.5303Z"
      />
    </svg>
  ),
});

const IconArrowRight = IconWrapper({
  name: 'arrow',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L14.0303 18.0303C13.7374 18.3232 13.2626 18.3232 12.9697 18.0303C12.6768 17.7374 12.6768 17.2626 12.9697 16.9697L17.1893 12.75L5 12.75C4.58579 12.75 4.25 12.4142 4.25 12C4.25 11.5858 4.58579 11.25 5 11.25L17.1893 11.25L12.9697 7.03033C12.6768 6.73744 12.6768 6.26256 12.9697 5.96967C13.2626 5.67678 13.7374 5.67678 14.0303 5.96967L19.5303 11.4697Z"
      />
    </svg>
  ),
});

const IconArrow = ({
  up,
  down,
  left,
  right,
  ...props
}: {
  up?: boolean;
  down?: boolean;
  left?: boolean;
  right?: boolean;
} & IconProps) => {
  if (up) return <IconArrowUp {...props} />;
  if (down) return <IconArrowDown {...props} />;
  if (left) return <IconArrowLeft {...props} />;
  if (right) return <IconArrowRight {...props} />;
  return null;
};

export default IconArrow;
