import { RefObject, useLayoutEffect } from 'react';

export const usePanElement = (elementRef: RefObject<HTMLElement | null>) => {
  useLayoutEffect(() => {
    const element = elementRef.current;

    if (!element) {
      return;
    }

    let isPan = false;

    const panStart = (ev: MouseEvent) => {
      ev.preventDefault();
      isPan = true;
    };

    const panMove = (ev: MouseEvent) => {
      if (isPan) {
        ev.preventDefault();
        element.scrollLeft -= ev.movementX;
        element.scrollTop -= ev.movementY;
      }
    };

    const panEnd = () => {
      isPan = false;
    };

    // Start panning on element, but allow cursor to move outside of it
    element.addEventListener('mousedown', panStart);
    window.addEventListener('mousemove', panMove);
    window.addEventListener('mouseup', panEnd);

    return () => {
      element.removeEventListener('mousedown', panStart);
      window.removeEventListener('mousemove', panMove);
      window.removeEventListener('mouseup', panEnd);
    };
  }, [elementRef]);
};
