import { useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import { InputDateRangeValue } from '@/design_system/InputDate/InputDateRange';
import Stack from '@/design_system/Stack';
import Tabs from '@/design_system/Tabs';
import { Tab, TabList, TabPanel } from '@/design_system/Tabs/Tabs';
import IconAdd from '@/icons/Add.svg';
import { InvoiceStatus, useCreateInvoice } from '@/models/invoice';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { useDebouncedSearchParamState } from '@/utils/useDebouncedState';
import { useSearchParamState, useSyncSearchParams } from '@/utils/useSearchParams';

import { InvoiceLinePaymentStrategy } from './InvoiceLines/components/filters/InvoiceLinePaymentStrategySelect';
import { InvoiceLines } from './InvoiceLines/InvoiceLines';
import { InvoiceDestination } from './Invoices/components/filters/InvoiceDestinationSelect';
import { InvoiceOrigin } from './Invoices/components/filters/InvoiceOriginSelect';
import { Invoices } from './Invoices/Invoices';
import { TransactionDestination } from './Transactions/components/filters/TransactionDestinationSelect';
import { TransactionOrigin } from './Transactions/components/filters/TransactionOriginSelect';
import { Transactions } from './Transactions/Transactions';

export const Accounting = () => {
  const { currentSession } = useCurrentSession();
  const [currentOrganization] = useCurrentOrganization();
  const navigate = useNavigate();
  const canViewInvoice = currentSession?.hasPermissionOnAnyScope('view_invoice');

  if (!canViewInvoice) {
    navigate('/');
  }

  const [tab, setTab, syncTab] = useSearchParamState<string>('accounting', 'tab', '');
  const [page, setPage, syncPage] = useSearchParamState<number>('accounting', 'page', 1);
  const [invoiceStatus, setInvoiceStatus, syncInvoiceStatus] = useSearchParamState<
    InvoiceStatus | ''
  >('accounting', 'invoiceStatus', '');
  const [invoiceOrigin, setInvoiceOrigin, syncInvoiceOrigin] = useSearchParamState<InvoiceOrigin>(
    'accounting',
    'invoiceOrigin',
    { type: 'all' }
  );
  const [invoiceDestination, setInvoiceDestination, syncInvoiceDestination] =
    useSearchParamState<InvoiceDestination>('accounting', 'invoiceDestination', {
      type: 'all',
    });
  const [transactionOrigin, setTransactionOrigin, syncTransactionOrigin] =
    useSearchParamState<TransactionOrigin>('accounting', 'transactionOrigin', { type: 'all' });
  const [transactionDestination, setTransactionDestination, syncTransactionDestination] =
    useSearchParamState<TransactionDestination>('accounting', 'transactionDestination', {
      type: 'all',
    });
  const [invoiceLineWorkshop, setInvoiceLineWorkshop, syncInvoiceLineWorkshop] =
    useSearchParamState<string>('accounting', 'invoiceLineWorkshop', '');
  const [
    invoiceLineOrganizationCountry,
    setInvoiceLineOrganizationCountry,
    syncInvoiceLineOrganizationCountry,
  ] = useSearchParamState<string>('accounting', 'invoiceLineOrganizationCountry', '');
  const [
    invoiceLinePaymentStrategy,
    setInvoiceLinePaymentStrategy,
    syncInvoiceLinePaymentStrategy,
  ] = useSearchParamState<InvoiceLinePaymentStrategy | ''>(
    'accounting',
    'invoiceLinePaymentStrategy',
    ''
  );
  const [dateRange, setDateRange, syncDateRange] = useSearchParamState<InputDateRangeValue>(
    'accounting',
    'dateRange',
    { option: 'any' }
  );

  const resetPage = useCallback(() => setPage(1), [setPage]);
  const [search, debouncedSearch, debouncedSetSearch, syncSearch] =
    useDebouncedSearchParamState<string>('accounting', 'search', '', 500, resetPage);

  useSyncSearchParams([
    syncTab,
    syncPage,
    syncSearch,
    syncInvoiceStatus,
    syncInvoiceOrigin,
    syncInvoiceDestination,
    syncTransactionOrigin,
    syncTransactionDestination,
    syncInvoiceLineWorkshop,
    syncInvoiceLineOrganizationCountry,
    syncInvoiceLinePaymentStrategy,
    syncDateRange,
  ]);

  const canCreateInvoice = currentSession?.hasPermission('create_invoice', {
    organizationId: currentOrganization?.id,
  });

  return (
    <>
      <div className="container container--list">
        <Stack gap="24px" style={{ height: '100%' }}>
          <Stack row alignItems="center">
            <h1 className="headline-200-bold" style={{ flex: 1 }}>
              <Trans id="accounting.title">Accounting</Trans>
            </h1>
            {canCreateInvoice && <CreateInvoiceButton />}
          </Stack>
          <Tabs
            gap="24px"
            selectedKey={tab}
            onSelectionChange={(tab) => {
              setTab(tab);
              setInvoiceStatus('');
              setInvoiceOrigin({ type: 'all' });
              setInvoiceDestination({ type: 'all' });
              setTransactionOrigin({ type: 'all' });
              setTransactionDestination({ type: 'all' });
              setInvoiceLineWorkshop('');
              setInvoiceLineOrganizationCountry('');
              setInvoiceLinePaymentStrategy('');
              setPage(1);
            }}
            style={{ flex: 1, paddingBottom: 24 }}
          >
            <TabList>
              <Tab id="invoices">
                <Trans id="accounting.tabs.invoices">Invoices</Trans>
              </Tab>
              <Tab id="transactions">
                <Trans id="accounting.tabs.transactions">Transactions</Trans>
              </Tab>
              {canCreateInvoice && (
                <Tab id="service-lines">
                  <Trans id="accounting.tabs.service-lines">Services to associate</Trans>
                </Tab>
              )}
            </TabList>
            <TabPanel id="invoices" style={{ flex: 1 }}>
              <Invoices
                {...{
                  page,
                  setPage,
                  search,
                  debouncedSearch,
                  debouncedSetSearch,
                  invoiceStatus,
                  setInvoiceStatus,
                  invoiceOrigin,
                  setInvoiceOrigin,
                  invoiceDestination,
                  setInvoiceDestination,
                  dateRange,
                  setDateRange,
                }}
              />
            </TabPanel>
            <TabPanel id="transactions" style={{ flex: 1 }}>
              <Transactions
                {...{
                  page,
                  setPage,
                  search,
                  debouncedSearch,
                  debouncedSetSearch,
                  transactionOrigin,
                  setTransactionOrigin,
                  transactionDestination,
                  setTransactionDestination,
                  dateRange,
                  setDateRange,
                }}
              />
            </TabPanel>
            {canCreateInvoice && (
              <TabPanel id="service-lines" style={{ flex: 1 }}>
                <InvoiceLines
                  {...{
                    page,
                    setPage,
                    search,
                    debouncedSearch,
                    debouncedSetSearch,
                    invoiceLineWorkshop,
                    setInvoiceLineWorkshop,
                    invoiceLineOrganizationCountry,
                    setInvoiceLineOrganizationCountry,
                    invoiceLinePaymentStrategy,
                    setInvoiceLinePaymentStrategy,
                    dateRange,
                    setDateRange,
                  }}
                />
              </TabPanel>
            )}
          </Tabs>
        </Stack>
      </div>
      <Outlet />
    </>
  );
};

const CreateInvoiceButton = () => {
  const { t } = useLingui();

  const navigate = useNavigate();

  const { mutateAsync: createInvoice, isPending: isPendingCreateInvoice } = useCreateInvoice();

  const handleCreateInvoice = async () => {
    const { id } = await createInvoice({});
    navigate(`/accounting/invoices/${id}`);
  };

  return (
    <>
      <Button
        className="is-hidden-mobile"
        size="medium"
        onPress={handleCreateInvoice}
        isLoading={isPendingCreateInvoice}
      >
        <IconAdd />
        <Trans id="accounting.new-invoice.label">New invoice</Trans>
      </Button>
      <Button
        className="is-hidden-from-tablet"
        size="large"
        iconOnly
        ariaLabel={t({ id: 'accounting.new-invoice.label', message: 'New invoice' })}
        tooltip={t({ id: 'accounting.new-invoice.label', message: 'New invoice' })}
        onPress={handleCreateInvoice}
        isLoading={isPendingCreateInvoice}
      >
        <IconAdd />
      </Button>
    </>
  );
};
