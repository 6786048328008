import { CSSProperties } from 'react';
import { useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useTransactionsOrigins } from '@/models/transaction';

export const TransactionOriginSelect = ({
  setPage,
  transactionOrigin,
  setTransactionOrigin,
  style,
}: {
  setPage?: (page: number) => void;
  transactionOrigin: TransactionOrigin;
  setTransactionOrigin: (origin: TransactionOrigin) => void;
  style?: CSSProperties;
}) => {
  const { t } = useLingui();

  const { data: transactionsOrigins, isFetching } = useTransactionsOrigins();

  const organizationCountries = (transactionsOrigins?.organizationCountries ?? []).map(
    (organizationCountry) => ({
      type: 'organization-country' as const,
      label: organizationCountry.name ?? '',
      id: organizationCountry.id,
    })
  );

  const clientOption = {
    type: 'client' as const,
    label: t({ id: 'accounting.transactions.origin.client', message: 'Client' }),
    id: undefined,
  };

  const options = transactionsOrigins?.client
    ? [clientOption, ...organizationCountries]
    : organizationCountries;

  const selectedOption =
    options.find(
      (option) => option.type === transactionOrigin.type && option.id === transactionOrigin.id
    ) ?? null;

  if (!options.length) {
    return null;
  }

  return (
    <InputSelect
      variant="select"
      placeholder={t({
        id: 'accounting.transactions.origin.placeholder',
        message: 'Origin: All',
      })}
      aria-label={t({ id: 'accounting.transactions.origin.label', message: 'Origin' })}
      value={selectedOption}
      isClearable
      options={options}
      getOptionValue={(option) => `${option.type}-${option.id}`}
      getOptionLabel={(option) => option.label}
      onChange={(option, action) => {
        if (action.action === 'clear') {
          setTransactionOrigin({ type: 'all' });
        } else if (option) {
          setTransactionOrigin({ type: option.type, id: option.id });
        }

        setPage?.(1);
      }}
      isLoading={isFetching}
      style={style}
    />
  );
};

export type TransactionOrigin = {
  type: 'all' | 'client' | 'organization-country';
  id?: string;
};
