import { Trans, useLingui } from '@lingui/react/macro';

import IconInvoice from '@/icons/Invoice.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const PendingInvoiceValidationNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'pending_invoice_validation'>;
}) => {
  const { t } = useLingui();

  const { invoice } = notification.data;

  return (
    <BaseNotification
      notificationLabel={t({
        id: 'notification.pending-invoice-validation.label',
        message: `You have a new invoice to validate`,
      })}
      icon={<IconInvoice />}
      type="new"
      notification={notification}
      notificationHeader={
        <Trans id="notification.pending-invoice-validation">
          <span className="paragraph-100-medium">You</span> have a new invoice to validate
        </Trans>
      }
      notificationHref={invoice && `/accounting/invoices/${invoice.id}`}
    />
  );
};
