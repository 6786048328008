import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Endpoints, Permission } from '@/api';
import { useFetch } from '@/utils/fetch';

import { Model } from './model';
import { Organization } from './organization';
import { Role } from './role';
import { Store } from './store';
import { instanciateUserWithRelations } from './user';
import { Workshop } from './workshop';

export interface UserRoleScope {
  organizationId?: string | null;
  storeId?: string | null;
  workshopId?: string | null;
}

export class UserRole extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  userId!: string | null;
  organizationId!: string | null;
  storeId!: string | null;
  workshopId!: string | null;

  roleId!: string;
  role!: Role;

  email!: string | null;
  invitationToken!: string | null;

  invitedAt!: string;
  get invitedAtDate() {
    return new Date(this.invitedAt);
  }

  hasPermission(permission: Permission, scope: UserRoleScope): boolean {
    // `scope` must have at least one non-nil value to be considered valid
    if (!Object.values(scope).some((value) => value !== null && value !== undefined)) {
      return false;
    }

    const isRoleMatchingScope =
      ('organizationId' in scope ? scope.organizationId === this.organizationId : true) &&
      ('storeId' in scope ? scope.storeId === this.storeId : true) &&
      ('workshopId' in scope ? scope.workshopId === this.workshopId : true);

    return isRoleMatchingScope && this.role.permissions.includes(permission);
  }

  hasPermissionOnAnyScope(permission: Permission): boolean {
    return this.role.permissions.includes(permission);
  }
}

export const useUserRole = ({ slug }: { slug: string }) => {
  const fetch = useFetch<Endpoints['GET /invite/current']>();

  return useQuery({
    queryKey: ['users', 'invite', 'current', slug],
    queryFn: () =>
      fetch(`/invite/current`, undefined, {
        headers: {
          'prolong-organization': slug,
        },
      }).then((data) =>
        new UserRole(data).with('organization', new Organization(data.organization))
      ),
  });
};

export const useInvitations = () => {
  const fetch = useFetch<Endpoints['GET /invite']>();

  return useQuery({
    queryKey: ['users', 'invite'],
    queryFn: () =>
      fetch('/invite').then((invitations) =>
        invitations.map(({ email, userRoles }) => ({
          email,
          userRoles: userRoles.map((userRole) =>
            new UserRole(userRole)
              .with('role', userRole.role)
              .with('store', userRole.store ? new Store(userRole.store) : undefined)
              .with('workshop', userRole.workshop ? new Workshop(userRole.workshop) : undefined)
          ),
        }))
      ),
  });
};

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /invite']>();

  return useMutation({
    mutationFn: (params: {
      email: string;
      userRoles: {
        id: string | null;
        roleId: string;
        organizationId: string | null;
        storeId: string | null;
        workshopId: string | null;
      }[];
    }) =>
      fetch('/invite', undefined, {
        method: 'POST',
        body: params,
      }).then(() => {
        queryClient.invalidateQueries({ queryKey: ['users', 'invite'] });
      }),
  });
};

export const useAcceptInvitationAndLogin = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /invite/:email/accept-and-login']>();

  return useMutation({
    mutationFn: ({
      email,
      ...body
    }: {
      email: string;
      invitationToken: string;
      name: string;
      password: string;
    }) =>
      fetch(`/invite/${email}/accept-and-login`, undefined, {
        method: 'POST',
        body,
      }).then((response) => ({
        user: instanciateUserWithRelations(response.user),
        flags: response.flags,
        publicKeys: response.publicKeys,
      })),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users', 'invite'] });
    },
  });
};

export const useUpdateInvite = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /invite/:userId']>();

  return useMutation({
    mutationFn: ({
      userId,
      ...body
    }: {
      userId: string;
      userRoles: {
        id: string | null;
        roleId: string;
        organizationId: string | null;
        storeId: string | null;
        workshopId: string | null;
      }[];
    }) =>
      fetch(`/invite/${userId}`, undefined, {
        method: 'PATCH',
        body,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useAcceptInvitation = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /invite/accept']>();

  return useMutation({
    mutationFn: ({ slug }: { slug: string }) =>
      fetch(`/invite/accept`, undefined, {
        method: 'POST',
        headers: {
          'prolong-organization': slug,
        },
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useDeleteUserRole = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['DELETE /invite/:emailOrUserId']>();

  return useMutation({
    mutationFn: (params: { email: string } | { userId: string }) =>
      fetch(`/invite/${'email' in params ? params.email : params.userId}`, undefined, {
        method: 'DELETE',
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useResendInvitation = () => {
  const fetch = useFetch<Endpoints['POST /invite/:email/resend-invitation']>();

  return useMutation({
    mutationFn: ({ email }: { email: string }) =>
      fetch(`/invite/${email}/resend-invitation`, undefined, {
        method: 'POST',
      }),
  });
};
