import cn from 'classnames';

import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import IconInfo from '@/icons/Info.svg';

export const Item = ({
  label,
  value,
  error,
  children,
}: {
  label: string;
  value?: React.ReactNode;
  error?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <Stack>
      <Stack
        row
        gap="4px"
        alignItems="center"
        className={cn('text-secondary', {
          'text-danger': !!error,
        })}
      >
        {!!error && <IconInfo style={{ fontSize: '1rem' }} />}
        <span className="paragraph-200-regular">{label}</span>
      </Stack>
      {!children && (
        <span className="paragraph-100-medium" aria-label={label}>
          {value || '-'}
        </span>
      )}
      {children}
      {!!error && error !== true && (
        <Message type="error" showMessageIcon={false}>
          {error}
        </Message>
      )}
    </Stack>
  );
};
