import { useCallback, useMemo, useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import debounce from 'lodash.debounce';

import InputText from '@/design_system/InputText';
import { useUpdateInvoice } from '@/models/invoice';

import { useInvoiceContext } from '../Invoice';

export const InvoiceNumber = () => {
  const { t } = useLingui();
  const { invoice, showErrors, errors, isDisabled } = useInvoiceContext();
  const { mutateAsync: updateInvoice } = useUpdateInvoice(invoice.id);

  const [number, setNumber] = useState(invoice.number);

  const updateNumber = useCallback(
    (number: string) => {
      updateInvoice({
        number,
      }).catch(() => setNumber(invoice.number));
    },
    [invoice.number, updateInvoice]
  );

  const debouncedUpdateNumber = useMemo(() => {
    return debounce(updateNumber, 500);
  }, [updateNumber]);

  return (
    <InputText
      label={<Trans id="invoice.form.number">Invoice Number</Trans>}
      placeholder={t({
        id: 'invoice.form.number.placeholder',
        message: 'Enter the invoice number...',
      })}
      value={number}
      onChange={(value) => {
        setNumber(value);
        debouncedUpdateNumber(value);
      }}
      isDisabled={isDisabled}
      isInvalid={showErrors && errors.number}
    />
  );
};
