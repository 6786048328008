import React, { useState } from 'react';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconChevron from '@/icons/Chevron.svg';
import useViewPort from '@/utils/useViewport';

const Accordion = ({
  trigger,
  children,
  openByDefault = false,
  ariaLabel,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
  openByDefault?: boolean;
  ariaLabel?: string;
}) => {
  const { isMobile } = useViewPort();
  const [isOpen, setIsOpen] = useState(openByDefault);

  return (
    <Stack justifyContent="space-between" alignItems="stretch">
      <Button ariaLabel={ariaLabel} variant="style-less" onPress={() => setIsOpen(!isOpen)}>
        <Stack
          style={{ flex: 1 }}
          padding={isMobile ? '0.75rem' : '0.5rem 1.5rem'}
          flexWrap="nowrap"
          row
          alignItems="center"
          justifyContent="space-between"
        >
          {trigger}
          <IconChevron down={!isOpen} up={isOpen} />
        </Stack>
      </Button>
      {isOpen && (
        <Stack padding={isMobile ? '0.25rem 0.75rem 0.75rem' : '0.5rem 1.5rem'} gap="0.5rem">
          {children}
        </Stack>
      )}
    </Stack>
  );
};

export default Accordion;
