import { CSSProperties } from 'react';
import { useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useTransactionsDestinations } from '@/models/transaction';

export const TransactionDestinationSelect = ({
  setPage,
  transactionDestination,
  setTransactionDestination,
  style,
}: {
  setPage?: (page: number) => void;
  transactionDestination: TransactionDestination;
  setTransactionDestination: (destination: TransactionDestination) => void;
  style?: CSSProperties;
}) => {
  const { t } = useLingui();

  const { data: transactionsDestinations, isFetching } = useTransactionsDestinations();

  const workshops = (transactionsDestinations?.workshops ?? []).map((workshop) => ({
    type: 'workshop' as const,
    label: workshop.name,
    id: workshop.id,
  }));

  const organizationCountries = (transactionsDestinations?.organizationCountries ?? []).map(
    (organizationCountry) => ({
      type: 'organization-country' as const,
      label: organizationCountry.name ?? '',
      id: organizationCountry.id,
    })
  );

  const options = [...workshops, ...organizationCountries].sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const selectedOption =
    options.find(
      (option) =>
        option.type === transactionDestination.type && option.id === transactionDestination.id
    ) ?? null;

  if (!options.length) {
    return null;
  }

  return (
    <InputSelect
      variant="select"
      placeholder={t({
        id: 'accounting.transactions.destination.placeholder',
        message: 'Destination: All',
      })}
      aria-label={t({ id: 'accounting.transactions.destination.label', message: 'Destination' })}
      value={selectedOption}
      isClearable
      options={options}
      getOptionValue={(option) => `${option.type}-${option.id}`}
      getOptionLabel={(option) => option.label}
      onChange={(option, action) => {
        if (action.action === 'clear') {
          setTransactionDestination({ type: 'all' });
        } else if (option) {
          setTransactionDestination({ type: option.type, id: option.id });
        }

        setPage?.(1);
      }}
      isLoading={isFetching}
      style={style}
    />
  );
};

export type TransactionDestination = {
  type: 'all' | 'workshop' | 'organization-country';
  id?: string;
};
