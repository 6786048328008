import { CSSProperties, ReactNode } from 'react';
import { Button as AriaButton, Input, Label, TextField } from 'react-aria-components';
import { useLingui } from '@lingui/react/macro';

import Hint from '@/design_system/Hint';
import Message, { MessageProps } from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import IconCancel from '@/icons/Cancel.svg';
import IconSearch from '@/icons/Search.svg';
import { createBEMClasses } from '@/utils/classname';

import './InputSearch.css';

const { block, element } = createBEMClasses('input-search');

export interface InputSearchProps {
  label?: ReactNode;
  ariaLabel?: string;
  placeholder?: string;
  size?: 'small' | 'medium' | 'large';
  hintText?: string;
  messageType?: MessageProps['type'];
  messageText?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isSuccess?: boolean;
  hideSearchIcon?: boolean;
  hideCancelIcon?: boolean;
  isLoading?: boolean;
  autoFocus?: boolean;

  value?: string;
  onChange?: (value: string) => void;

  style?: CSSProperties;
  className?: string;
}

const InputSearch = ({
  value,
  onChange,
  style,
  className,
  ariaLabel,
  ...props
}: InputSearchProps) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      className={className}
      style={style}
      aria-label={ariaLabel}
    >
      <InputSearchContent {...props} value={value} onChange={onChange} />
    </TextField>
  );
};

export const InputSearchContent = ({
  label,
  ariaLabel,
  placeholder,
  size = 'medium',
  hintText,
  messageType,
  messageText,
  isDisabled,
  isInvalid,
  isSuccess,
  hideSearchIcon,
  hideCancelIcon,
  isLoading,
  value,
  onChange,
  autoFocus,
}: InputSearchProps) => {
  const { t } = useLingui();

  const hideCancel = hideCancelIcon || isDisabled || !value;

  return (
    <Stack gap="4px" ariaLabel={ariaLabel} className={block()}>
      {label && <Label className="label-100 text-primary">{label}</Label>}

      <div
        className={element('control', {
          size,
          'no-search': hideSearchIcon,
          'no-icon-right': hideCancel && !isLoading,
          loading: isLoading,
          disabled: isDisabled,
          invalid: isInvalid,
          success: isSuccess,
        })}
      >
        {!hideSearchIcon && <IconSearch />}
        <Input
          placeholder={placeholder}
          disabled={isDisabled}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
        />
        {!hideCancel && !isLoading && (
          <AriaButton
            onPress={() => onChange?.('')}
            aria-label={t({
              id: 'design-system.input-search.clear',
              message: 'Clear search input',
            })}
            aria-labelledby=""
          >
            <IconCancel />
          </AriaButton>
        )}
      </div>

      {hintText && <Hint>{hintText}</Hint>}
      {messageText && <Message type={messageType}>{messageText}</Message>}
    </Stack>
  );
};

export default InputSearch;
