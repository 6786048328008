import { Trans } from '@lingui/react/macro';

import { FileViewerUpload } from '@/components/FileUpload/FileViewerUpload';
import Stack from '@/design_system/Stack';

import { useInvoiceContext } from '../Invoice';

export const InvoiceMedia = () => {
  const { invoice, showErrors, errors, isDisabled } = useInvoiceContext();

  return (
    <Stack gap="4px" style={{ flex: '1 1 400px', minHeight: '400px', maxWidth: '100%' }}>
      <h2 className="label-100">
        <Trans id="invoice.media.title">Invoice</Trans>
      </h2>
      <FileViewerUpload
        uploadData={{ type: 'workshop-invoice', invoiceId: invoice.id }}
        media={invoice.media.filter(
          (medium) => medium.type === 'workshop-invoice' || medium.type === 'invoice'
        )}
        isInvalid={showErrors && errors.media}
        isDisabled={isDisabled}
        deleteWithApi
      />
    </Stack>
  );
};
