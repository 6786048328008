import { useState } from 'react';
import { Trans } from '@lingui/react/macro';

import { PriceAmountWithDetails } from '@/api';
import { PriceDetails } from '@/components/PriceDetails/PriceDetails';
import { usePriceDetailsRows } from '@/components/PriceWithDetails/usePriceDetailsRows';
import Stack from '@/design_system/Stack';
import Toggle from '@/design_system/Toggle';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';

export const RequestOrganizationPrice = ({
  price,
  label,
}: {
  price: PriceAmountWithDetails | null | undefined;
  label?: string;
}) => {
  const { view: requestView } = useRequestContext();
  const [showDetails, setShowDetails] = useState(false);

  const priceDetailsRows = usePriceDetailsRows(price, {
    showTaxDetails: showDetails,
    showAmountBeforeTaxes: requestView.price.showAmountBeforeTaxes,
  });

  if (!price) return null;

  return (
    <Stack
      ariaLabel={label}
      className="bg-neutral-300"
      gap="0.5rem"
      padding="0.75rem 1rem"
      style={{ borderRadius: '0.5rem' }}
    >
      <Stack row gap="0.5rem" justifyContent="space-between">
        <p>{label && <span className="paragraph-100-medium text-primary">{label}</span>}</p>
        <Stack row gap="0.25rem" alignItems="center">
          <Toggle
            label={<Trans id="request.articles.price-details">Details</Trans>}
            size="small"
            isSelected={showDetails}
            onChange={() => setShowDetails(!showDetails)}
          />
        </Stack>
      </Stack>
      <PriceDetails priceDetailsRows={priceDetailsRows} currency={price.currency} />
    </Stack>
  );
};
