import { Plural, Trans, useLingui } from '@lingui/react/macro';

import IconInvoice from '@/icons/Invoice.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const ServicesToAssociateNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'services_to_associate'>;
}) => {
  const { t } = useLingui();

  const { nbServicesToAssociate } = notification.data;

  return (
    <BaseNotification
      notificationLabel={t({
        id: 'notification.services-to-associate.label',
        message: `You have services to associate to invoices`,
      })}
      icon={<IconInvoice />}
      type="warning"
      notification={notification}
      notificationHeader={
        <Trans id="notification.services-to-associate">
          <span className="paragraph-100-medium">You</span> have {nbServicesToAssociate}{' '}
          <Plural value={nbServicesToAssociate} one="service" other="services" /> to associate to an
          invoice
        </Trans>
      }
      notificationHref="/accounting?tab=service-lines"
    />
  );
};
