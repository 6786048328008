import { Trans } from '@lingui/react/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconAnalysisIssue from '@/icons/AnalysisIssue.svg';

export const ArticleDispatchProposalSubmittedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_dispatch_proposal_submitted'>;
  displayArticleName: boolean;
}) => {
  const workshopName = activity.workshop.name;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="warning"
      icon={<IconAnalysisIssue />}
      creatorName={`${activity.creator.name}${!activity.data?.onBehalfOfWorkshop ? ` (${workshopName})` : ''}`}
      message={
        <>
          <BaseActivityText>
            {activity.data?.onBehalfOfWorkshop ? (
              <Trans id="activities.article_dispatch_proposal_submitted.message.on-behalf">
                accepted the job and proposed a new quote for the item{' '}
                <BaseActivityArticleName
                  activity={activity}
                  displayArticleName={displayArticleName}
                />{' '}
                on behalf of {workshopName}
              </Trans>
            ) : (
              <Trans id="activities.article_dispatch_proposal_submitted.message">
                accepted the job and proposed a new quote for the item{' '}
                <BaseActivityArticleName
                  activity={activity}
                  displayArticleName={displayArticleName}
                />
              </Trans>
            )}
          </BaseActivityText>
        </>
      }
    />
  );
};
