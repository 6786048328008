import { Trans, useLingui } from '@lingui/react/macro';

import Accordion from '@/design_system/Accordion';
import Button from '@/design_system/Button';
import { Separator } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import IconEdit from '@/icons/Edit.svg';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useCurrentSession, useFlags } from '@/services/auth';

import { ArticleInformationFormDrawer } from '../../ArticleInformationFormDrawer';
import { ArticlePriceWithTitle } from '../../ArticlePrice';

import { ArticleDetails } from './ArticleDetails';
import { ArticleWarranty } from './ArticleWarranty';
import { ArticleWorkshopPayment } from './ArticleWorkshopPayment';

export const ArticleInfoSidePanel = () => {
  const { t } = useLingui();
  const { currentSession } = useCurrentSession();
  const { article, view, state } = useArticleContext();
  const { flags } = useFlags();

  const canEditArticleInfo =
    currentSession!.hasPermission('edit_article', {
      organizationId: article.organizationId,
    }) && !article.archivedAt;

  const showWorkshopPaymentSection =
    article.repairedAt !== null &&
    !!article.workshop?.nonDigitalized &&
    flags['enable-accounting-section'];

  return (
    <Stack gap="0.5rem">
      {article.task?.type !== 'choose_article_service' && (
        <>
          <Stack padding="0.5rem 1.5rem">
            <ArticlePriceWithTitle allowDiscount={false} />
          </Stack>
          <Separator />
        </>
      )}

      {showWorkshopPaymentSection && (
        <>
          <Accordion
            openByDefault
            trigger={
              <Stack row gap="0.5rem" alignItems="center">
                <p className="paragraph-100-medium">
                  <Trans id="article.workshop-payment.title">Workshop payment</Trans>
                </p>
              </Stack>
            }
          >
            <ArticleWorkshopPayment />
          </Accordion>
          <Separator />
        </>
      )}

      <Stack ariaLabel={t({ id: 'article.information.title', message: 'Information' })}>
        <Accordion
          openByDefault
          trigger={
            <Stack row gap="0.5rem" alignItems="center">
              <p className="paragraph-100-medium">
                <Trans id="article.information.title">Information</Trans>
              </p>
            </Stack>
          }
        >
          <ArticleDetails />
          {canEditArticleInfo && (
            <Button
              variant="secondary"
              onPress={() => state.infoPanel.setIsOpen(true)}
              size="small"
              ariaLabel={t({ id: 'article.edit.actions.edit-article', message: 'Edit' })}
            >
              <IconEdit />
              <Trans id="article.edit.actions.edit-article">Edit</Trans>
            </Button>
          )}
        </Accordion>

        <ArticleInformationFormDrawer />
      </Stack>

      {view.warranty.shown && !view.warranty.editable && (
        <>
          <Separator />

          <Stack>
            <Accordion
              openByDefault
              trigger={
                <Stack row gap="0.5rem" alignItems="center">
                  <p className="paragraph-100-medium">
                    <Trans id="article.warranty.title">Warranty</Trans>
                  </p>
                </Stack>
              }
            >
              <ArticleWarranty />
            </Accordion>
          </Stack>
        </>
      )}
    </Stack>
  );
};
