import { Trans, useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import InputText from '@/design_system/InputText';
import { useInvoicesOrigins, useUpdateInvoice } from '@/models/invoice';

import { useInvoiceContext } from '../Invoice';

export const InvoiceOrigin = () => {
  const { invoice, isDisabled } = useInvoiceContext();

  const isInputDisabled = isDisabled || !!invoice.lines.length;

  if (isInputDisabled) {
    return (
      <InputText
        label={<Trans id="invoice.form.origin">Issuer</Trans>}
        value={invoice.originWorkshop?.name ?? invoice.originOrganizationCountry?.name}
        isDisabled
        style={{ flex: '1 1 200px' }}
      />
    );
  }

  return <InvoiceOriginWorkshopSelect />;
};

const InvoiceOriginWorkshopSelect = () => {
  const { t } = useLingui();
  const { invoice, showErrors, errors } = useInvoiceContext();
  const { mutate: updateInvoice } = useUpdateInvoice(invoice.id);

  const { data: invoicesOrigins, isFetching } = useInvoicesOrigins();

  return (
    <InputSelect
      label={<Trans id="invoice.form.origin">Issuer</Trans>}
      variant="select"
      placeholder={t({
        id: 'accounting.invoices.origin.placeholder',
        message: 'Select an invoice issuer',
      })}
      aria-label={t({ id: 'accounting.invoices.origin.label', message: 'Issuer' })}
      value={invoice.originWorkshop}
      isClearable
      options={invoicesOrigins?.workshops}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      onChange={(option, action) => {
        if (action.action === 'clear') {
          updateInvoice({
            originWorkshop: null,
          });
        } else if (option) {
          updateInvoice({
            originWorkshop: option,
          });
        }
      }}
      isLoading={isFetching}
      style={{ flex: '1 1 200px' }}
      isInvalid={showErrors && errors.origin}
    />
  );
};
