import { Plural, Trans } from '@lingui/react/macro';

import { CardItem } from '@/components/Card/Card';
import Stack from '@/design_system/Stack';
import IconTools from '@/icons/Tools.svg';
import { ArticleWithRelations } from '@/models/request';

export const ArticleServiceTypeCell = ({ article }: { article: ArticleWithRelations }) => {
  const numberOfActions = article.numberOfActions;
  return (
    <Stack style={{ overflow: 'hidden' }}>
      {!article.serviceChoice && <span className="paragraph-100-medium text-secondary">-</span>}
      {article.serviceChoice === 'care-repair' && (
        <>
          <span className="paragraph-100-medium text-ellipsis">
            <Trans id="request.articles.table.column.service-type.care-and-repair">
              Care & repair
            </Trans>
          </span>
          <span className="paragraph-200-regular text-secondary">
            <Trans id="request.articles.table.column.service-type.actions">
              {numberOfActions} <Plural value={numberOfActions} one="action" other="actions" />
            </Trans>
          </span>
        </>
      )}
      {article.serviceChoice === 'exchange' && (
        <span className="paragraph-100-medium">
          <Trans id="request.articles.table.column.service-type.exchange">Exchange</Trans>
        </span>
      )}
      {article.serviceChoice === 'refund' && (
        <span className="paragraph-100-medium">
          <Trans id="request.articles.table.column.service-type.refund">Refund</Trans>
        </span>
      )}
      {article.serviceChoice === 'none' && (
        <span className="paragraph-100-medium">
          <Trans id="request.articles.table.column.service-type.none">None</Trans>
        </span>
      )}
    </Stack>
  );
};

export const ArticleServiceTypeCardItem = ({ article }: { article: ArticleWithRelations }) => {
  const numberOfActions = article.numberOfActions;
  return (
    <CardItem>
      <IconTools style={{ fontSize: '1rem' }} />
      <Trans id="request.articles.table.column.service-type.actions">
        {numberOfActions} {}
        <Plural value={numberOfActions} one="action" other="actions" />
      </Trans>
    </CardItem>
  );
};
