import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useLocalStorageState } from '@/services/localStorage';

export const useSearchParamState = <T extends string | number | string[] | Record<string, string>>(
  group: string,
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>, [string, T, T]] => {
  const entries = [...new URLSearchParams(window.location.search).entries()];

  let initialValue = defaultValue;

  if (Array.isArray(defaultValue)) {
    const arrayEntries = entries.filter(([entryKey]) => entryKey === `${key}[]`);

    if (arrayEntries.length) {
      initialValue = arrayEntries.map(([, entryValue]) => entryValue) as T;
    }
  } else if (typeof defaultValue === 'object') {
    const objectEntries = entries.filter(
      ([entryKey]) =>
        entryKey.startsWith(`${key}[`) && entryKey.endsWith(`]`) && entryKey !== `${key}[]`
    );

    if (objectEntries.length) {
      initialValue = objectEntries.reduce(
        (acc, [entryKey, entryValue]) => {
          const objectKey = entryKey.slice(key.length + 1, -1);
          acc[objectKey] = entryValue;
          return acc;
        },
        {} as Record<string, string>
      ) as T;
    }
  } else {
    const scalarEntry = entries.find(([entryKey]) => entryKey === key);

    if (scalarEntry) {
      if (typeof defaultValue === 'number') {
        initialValue = Number(scalarEntry[1]) as T;
      } else {
        initialValue = scalarEntry[1] as T;
      }
    }
  }

  const [state, setState] = useLocalStorageState<T>(
    `search-param:${group}:${key}`,
    initialValue,
    !!window.location.search
  );

  return [state, setState, [key, state, defaultValue]];
};

/**
 * Important: search params are subject to race conditions.
 * Make sure this hook is used only once per page.
 */
export const useSyncSearchParams = (
  syncParams: (
    | [string, number, number]
    | [string, string, string]
    | [string, string[], string[]]
    | [string, Record<string, string>, Record<string, string>]
  )[]
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams: string[] = [];

  syncParams
    .filter(([, value, defaultValue]) => JSON.stringify(value) !== JSON.stringify(defaultValue))
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => searchParams.push(`${key}[]=${item}`));
      } else if (typeof value === 'object') {
        Object.entries(value).forEach(([entryKey, entryValue]) =>
          searchParams.push(`${key}[${entryKey}]=${entryValue}`)
        );
      } else if (value !== undefined) {
        searchParams.push(`${key}=${value.toString()}`);
      }
    });

  const searchParamsString = searchParams.join('&');

  useEffect(() => {
    // TODO: this is a hack to prevent the search params from being added to the URL when creating a new request/shipment/invoice
    // We should find a better way to do this
    if (
      window.location.pathname.endsWith('/new') ||
      window.location.pathname.includes('/accounting/invoices/')
    ) {
      return;
    }
    navigate(`?${searchParamsString}`, {
      replace: true,
      state: location.state, // Keep existing state
    });
  }, [navigate, searchParamsString, location.state]);
};
