import { useState } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { useValidateServiceChoice } from '@/models/article';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import ArticlePrice from '@/routes/Requests/Request/Article/ArticlePrice';
import { useFlags } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import { ArticleActionProps } from './ArticleActions';

export const ChooseArticleServiceAction = ({ onActionDone }: ArticleActionProps) => {
  const { t } = useLingui();
  const { isMobile } = useViewPort();
  const { flags } = useFlags();

  const { view: requestView } = useRequestContext();
  const { article, view, state, hasError } = useArticleContext();

  const [apiError, setApiError] = useState<string | null>(null);

  const {
    mutateAsync: validateServiceChoice,
    isPending: isPendingValidateServiceChoice,
    isSuccess: isSuccessValidateServiceChoice,
  } = useValidateServiceChoice({
    articleId: article.id,
  });

  const handleValidateServiceChoice = async () => {
    const { hasError } = state.errors.check({
      details: {
        product: true,
        productL2: true,
        productL3: true,
        otherBrandEmpty: true,
        missingArticlePhoto: true,
        customs: view.services.dispatch.shown,
      },
      warranty: {
        purchaseDate: view.warranty.shown,
        missingProofOfPurchase: view.warranty.shown,
      },
      services: {
        defects: view.services.defects.shown
          ? {
              noDefects: true,
              defects: {
                missingDescription: true,
                missingPhotos: true,
                missingActions: view.services.actions.shown,
              },
            }
          : undefined,
        choice: view.services.choice.shown
          ? {
              noChoice: true,
            }
          : undefined,
        actions: view.services.actions.shown
          ? {
              noActions: true,
              actions: {
                missingDescription: true,
                missingPhotos: !view.services.defects.shown,
                missingCost: flags['enable-dispatch-cost-edition']
                  ? false
                  : requestView.cost.enabled,
                missingPrice: requestView.price.enabled,
              },
            }
          : undefined,
        dispatch: view.services.dispatch.shown
          ? {
              noWorkshopSelected: true,
            }
          : undefined,
      },
    });
    setApiError(null);

    if (!hasError) {
      try {
        await validateServiceChoice();
        onActionDone();
      } catch (err: any) {
        console.error(err);
        setApiError(
          (err.message as string) ?? t({ id: '_general.error.unknown', message: 'Unknown error' })
        );
      }
    }
  };

  return (
    <Stack gap="1rem" row alignItems="center" justifyContent="space-between" style={{ flex: 1 }}>
      {hasError && (
        <p className="paragraph-100-medium text-danger">
          <Trans id="article.actions.choose_article_service.errors">
            Please fill the missing information in order to validate the service choice
          </Trans>
        </p>
      )}
      {apiError && <p className="paragraph-100-medium text-danger">{apiError}</p>}
      <Stack
        row={!isMobile}
        gap={isMobile ? '0.5rem' : '1.5rem'}
        alignItems={isMobile ? 'stretch' : 'center'}
        style={{ flex: '1' }}
        justifyContent="flex-end"
        flexWrap="nowrap"
      >
        <Stack row justifyContent={isMobile ? 'flex-end' : 'center'}>
          <ArticlePrice allowDiscount />
        </Stack>
        {!isMobile && (
          <div
            style={{ backgroundColor: 'var(--color-neutral-300)', width: '1px', height: '24px' }}
          ></div>
        )}
        <Button
          variant="primary"
          size="medium"
          onPress={handleValidateServiceChoice}
          isLoading={isPendingValidateServiceChoice || isSuccessValidateServiceChoice}
          className="text-no-wrap"
        >
          <Trans id="article.actions.choose_article_service.validate">Validate the choice</Trans>
        </Button>
      </Stack>
    </Stack>
  );
};
