import { CSSProperties } from 'react';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';

export const InvoiceLinePaymentStrategySelect = ({
  setPage,
  invoiceLinePaymentStrategy,
  setInvoiceLinePaymentStrategy,
  style,
}: {
  setPage?: (page: number) => void;
  invoiceLinePaymentStrategy: InvoiceLinePaymentStrategy | '';
  setInvoiceLinePaymentStrategy: (
    invoiceLinePaymentStrategy: InvoiceLinePaymentStrategy | ''
  ) => void;
  style?: CSSProperties;
}) => {
  const { i18n, t } = useLingui();

  return (
    <InputSelect
      variant="select"
      placeholder={t({
        id: 'accounting.invoice-lines.filters.payment-strategy.placeholder',
        message: 'Payment type: All',
      })}
      aria-label={t({
        id: 'accounting.invoice-lines.filters.payment-strategy.label',
        message: 'Payment type',
      })}
      value={options.find((option) => invoiceLinePaymentStrategy === option.id) ?? null}
      isClearable
      options={options}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => i18n._(option.text)}
      onChange={(option, action) => {
        if (action.action === 'clear') {
          setInvoiceLinePaymentStrategy('');
        } else if (option) {
          setInvoiceLinePaymentStrategy(option.id);
        }

        setPage?.(1);
      }}
      style={style}
    />
  );
};

const options = [
  {
    id: 'handled-by-accounting-team',
    text: msg({
      id: 'accounting.invoice-lines.filters.payment-strategy.options.handled-by-accounting-team',
      message: 'Accounting department',
    }),
  },
  {
    id: 'store-cash-out',
    text: msg({
      id: 'accounting.invoice-lines.filters.payment-strategy.options.store-cash-out',
      message: 'Store payment (cash out)',
    }),
  },
] as const;

export type InvoiceLinePaymentStrategy = (typeof options)[number]['id'];
