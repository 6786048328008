import { CSSProperties, ReactNode } from 'react';
import { Input, Label, NumberField, NumberFieldProps } from 'react-aria-components';

import Hint from '@/design_system/Hint';
import Message, { MessageProps } from '@/design_system/Message';
import { createBEMClasses } from '@/utils/classname';
import { Currency } from '@/utils/number';

import './InputNumber.css';

export type InputNumberProps = {
  label?: ReactNode;
  ariaLabel?: string;
  placeholder?: string;
  size?: 'small' | 'large';
  hintText?: string;
  messageType?: MessageProps['type'];
  messageText?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
  error?: string;

  autoFocus?: boolean;

  value?: number;
  defaultValue?: number;
  /**
   * Called on blur.
   */
  onChange?: (value: number) => void;
  onBlur?: NumberFieldProps['onBlur'];
  formatOptions?: Intl.NumberFormatOptions;
  minValue?: number;
  maxValue?: number;

  style?: CSSProperties;
  className?: string;
};

const { block } = createBEMClasses('input-number');

const InputNumber = ({
  label,
  ariaLabel,
  placeholder,
  size = 'large',
  hintText,
  messageType,
  messageText,
  isInvalid,
  isRequired,
  isLoading,
  error,
  className,
  autoFocus,
  ...props
}: InputNumberProps) => {
  return (
    <NumberField
      aria-label={ariaLabel}
      className={block({ size, loading: isLoading }, className)}
      isInvalid={isInvalid || !!error}
      {...props}
    >
      {label && <Label className="label-100 text-primary">{label}</Label>}
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <Input placeholder={placeholder} required={isRequired} autoFocus={autoFocus} />
      {hintText && <Hint>{hintText}</Hint>}
      {error && <Message type="error">{error}</Message>}
      {messageText && <Message type={messageType}>{messageText}</Message>}
    </NumberField>
  );
};

export interface InputMoneyProps extends InputNumberProps {
  currency: Currency;
}

/**
 * Configures InputNumber for currency formatting
 * And handles the value in cents
 */
export const InputMoney = ({
  defaultValue,
  value,
  currency,
  onChange,
  minValue,
  formatOptions,
  ...props
}: InputMoneyProps) => (
  <InputNumber
    {...props}
    defaultValue={defaultValue !== undefined ? defaultValue / 100 : undefined}
    value={value !== undefined ? value / 100 : undefined}
    onChange={(value) => onChange?.(Math.round(value * 100))}
    minValue={minValue ?? 0}
    formatOptions={{
      style: 'currency',
      currency,

      ...formatOptions,
    }}
  />
);

/**
 * Configures InputNumber for percentage formatting
 * And handles the value in percentage (0 to 100)
 */
export const InputPercentage = ({
  defaultValue,
  value,
  onChange,
  formatOptions,
  minValue,
  maxValue,
  ...props
}: InputNumberProps) => (
  <InputNumber
    {...props}
    defaultValue={defaultValue !== undefined ? defaultValue / 100 : undefined}
    value={value !== undefined ? value / 100 : undefined}
    onChange={(value) => onChange?.(Math.round(value * 100))}
    minValue={minValue ?? 0}
    maxValue={maxValue ?? 1}
    formatOptions={{
      style: 'percent',
      ...formatOptions,
    }}
  />
);

export default InputNumber;
