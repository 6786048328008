import { Currency } from '@/utils/number';

import { Model } from './model';

export class Price extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  amount!: number;
  amountBeforeTaxes!: number;
  currency!: Currency;
}
