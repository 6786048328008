import { Trans, useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import InputText from '@/design_system/InputText';
import { useInvoicesDestinations, useUpdateInvoice } from '@/models/invoice';

import { useInvoiceContext } from '../Invoice';

export const InvoiceDestination = () => {
  const { invoice, isDisabled } = useInvoiceContext();

  const isInputDisabled = isDisabled || !!invoice.lines.length;

  if (isInputDisabled) {
    return (
      <InputText
        label={<Trans id="invoice.form.destination">Recipient</Trans>}
        value={invoice.destinationOrganizationCountry?.name ?? invoice.destinationClient?.name}
        isDisabled
        style={{ flex: '1 1 200px' }}
      />
    );
  }

  return <InvoiceDestinationOrganizationCountrySelect />;
};

const InvoiceDestinationOrganizationCountrySelect = () => {
  const { t } = useLingui();
  const { invoice, showErrors, errors } = useInvoiceContext();
  const { mutate: updateInvoice } = useUpdateInvoice(invoice.id);

  const { data: invoicesDestinations, isFetching } = useInvoicesDestinations();

  return (
    <InputSelect
      label={<Trans id="invoice.form.destination">Recipient</Trans>}
      variant="select"
      placeholder={t({
        id: 'accounting.invoices.destination.placeholder',
        message: 'Select the recipient',
      })}
      aria-label={t({ id: 'accounting.invoices.destination.label', message: 'Recipient' })}
      value={invoice.destinationOrganizationCountry}
      isClearable
      options={invoicesDestinations?.organizationCountries}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name ?? ''}
      onChange={(option, action) => {
        if (action.action === 'clear') {
          updateInvoice({
            destinationOrganizationCountry: null,
          });
        } else if (option) {
          updateInvoice({
            destinationOrganizationCountry: option,
          });
        }
      }}
      isLoading={isFetching}
      style={{ flex: '1 1 200px' }}
      isInvalid={showErrors && errors.destination}
    />
  );
};
