import { Trans } from '@lingui/react/macro';

import RadioGroup from '@/design_system/RadioGroup';
import { useUpdateInvoice } from '@/models/invoice';

import { useInvoiceContext } from '../Invoice';

export const InvoicePaymentStrategy = () => {
  const { invoice, isDisabled } = useInvoiceContext();
  const { mutate: updateInvoice } = useUpdateInvoice(invoice.id);

  if (invoice.destinationClient) {
    return null;
  }

  if (isDisabled) {
    return (
      <RadioGroup
        label={<Trans id="invoice.form.payment-strategy">Payment responsible</Trans>}
        options={options.filter(({ value }) => value === invoice.paymentStrategy)}
        isDisabled
      />
    );
  }

  return (
    <RadioGroup
      label={<Trans id="invoice.form.payment-strategy">Payment responsible</Trans>}
      options={options.filter(({ value }) => value !== 'prolong-payout')}
      value={invoice.paymentStrategy}
      onChange={(paymentStrategy) =>
        updateInvoice({
          paymentStrategy: paymentStrategy as 'store-cash-out' | 'handled-by-accounting-team',
        })
      }
      isDisabled={!!invoice.lines.length}
    />
  );
};

const options = [
  {
    value: 'store-cash-out',
    text: <Trans id="invoice.form.payment-strategy.store-cash-out.text">The store</Trans>,
    subText: (
      <Trans id="invoice.form.payment-strategy.store-cash-out.subtext">
        Via a cash register operation
      </Trans>
    ),
  },
  {
    value: 'handled-by-accounting-team',
    text: (
      <Trans id="invoice.form.payment-strategy.handled-by-accounting-team.text">
        The accounting department
      </Trans>
    ),
    subText: (
      <Trans id="invoice.form.payment-strategy.handled-by-accounting-team.subtext">
        Via bank transfer at the end of the month
      </Trans>
    ),
  },
  {
    value: 'prolong-payout',
    text: <Trans id="invoice.form.payment-strategy.prolong-payout.text">Prolong</Trans>,
    subText: <Trans id="invoice.form.payment-strategy.prolong-payout.subtext">Via Stripe</Trans>,
  },
] as const;
