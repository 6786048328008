import { Trans } from '@lingui/react/macro';

import InputDate from '@/design_system/InputDate';
import { useUpdateInvoice } from '@/models/invoice';

import { useInvoiceContext } from '../Invoice';

export const InvoicePaymentDueDate = () => {
  const { invoice, showErrors, errors, isDisabled } = useInvoiceContext();
  const { mutate: updateInvoice } = useUpdateInvoice(invoice.id);

  if (invoice.destinationClient) {
    return <div style={{ flex: '1 1 200px' }} />;
  }

  return (
    <InputDate
      label={<Trans id="invoice.form.payment-due-at">Payment due date</Trans>}
      value={invoice.paymentDueDate}
      onChange={(date) =>
        updateInvoice({
          paymentDueDate: date,
        })
      }
      style={{ flex: '1 1 200px' }}
      isDisabled={isDisabled}
      isInvalid={showErrors && errors.paymentDueDate}
    />
  );
};
