import { Trans } from '@lingui/react/macro';

import FileUpload from '@/components/FileUpload';
import IconReceipt from '@/icons/Receipt.svg';

import { useInvoiceContext } from '../Invoice';

export const InvoicePaymentReceipt = () => {
  const { invoice, isDisabled } = useInvoiceContext();

  if (invoice.paymentStrategy !== 'store-cash-out') {
    return null;
  }

  const paymentReceipts = invoice.media.filter((medium) => medium.type === 'payment-receipt');

  if (isDisabled && !paymentReceipts.length) {
    return null;
  }

  return (
    <FileUpload
      uploadData={{ type: 'payment-receipt', invoiceId: invoice.id }}
      media={paymentReceipts}
      disabled={isDisabled}
      deleteWithApi
      label={
        <>
          <Trans id="invoice.form.payment-receipt">Payment receipt</Trans>{' '}
          <span className="text-disabled paragraph-100-regular">
            <Trans id="invoice.form.payment-receipt.optional">(Optional)</Trans>
          </span>
        </>
      }
      icon={<IconReceipt />}
      prompt={<Trans id="invoice.form.payment-receipt.prompt">Drop the payment receipt here</Trans>}
      allowsMultiple={false}
      maxNumberOfMedia={1}
    />
  );
};
