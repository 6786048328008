import { ComponentProps, HTMLInputAutoCompleteAttribute, useId } from 'react';
import Select, {
  type ClearIndicatorProps,
  components,
  type ControlProps,
  type DropdownIndicatorProps,
  type GroupBase,
  type InputProps,
  type MultiValueGenericProps,
  type MultiValueRemoveProps,
  type OptionProps,
  type Props as SelectProps,
  type ValueContainerProps,
} from 'react-select';
import { Trans } from '@lingui/react/macro';

import Loader from '@/components/Loader';
import Button from '@/design_system/Button';
import Chip from '@/design_system/Chip';
import Hint from '@/design_system/Hint';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconAdd from '@/icons/Add.svg';
import IconChevron from '@/icons/Chevron.svg';
import IconCross from '@/icons/Cross.svg';
import IconInfo from '@/icons/Info.svg';
import IconSearch from '@/icons/Search.svg';
import { createBEMClasses } from '@/utils/classname';

import './InputSelect.css';

const { block, element } = createBEMClasses('input-select');

export type InputSelectVariant = 'search' | 'select' | 'add';

export type InputSelectProps<
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
  hintText?: React.ReactNode;
  error?: string;
  isInvalid?: boolean;
  variant?: InputSelectVariant;
  styleVariant?: 'default' | 'brand';
  multiValueProperty?: string; // Property to display in each selected value in multi select field
  onRemoveValue?: (selectedKey: string) => void;
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties;
} & SelectProps<Option, IsMulti, Group>;

const ValueContainer = <
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
  /* @ts-ignore As mentioned in react-select docs https://react-select.com/components, it is the only way to access custom props inside custom components */
  const { variant } = props.selectProps;

  return (
    <Stack row gap="0.25rem" alignItems="center" style={{ overflow: 'hidden', flex: 1 }}>
      {variant === 'search' && <IconSearch />}
      {variant === 'add' && <IconAdd />}
      <components.ValueContainer<Option, IsMulti, Group> {...props}>
        {children}
      </components.ValueContainer>
    </Stack>
  );
};

const Control = <
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: ControlProps<Option, IsMulti, Group>) => {
  /* @ts-ignore As mentioned in react-select docs https://react-select.com/components, it is the only way to access custom props inside custom components */
  const { isMulti, styleVariant, variant, size } = props.selectProps;

  return (
    <components.Control<Option, IsMulti, Group>
      {...props}
      className={element('control', { isMulti, styleVariant, variant, size })}
    >
      {children}
    </components.Control>
  );
};

const MultiValueContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: MultiValueGenericProps<Option, IsMulti, Group>) => {
  /* @ts-ignore As mentioned in react-select docs https://react-select.com/components, it is the only way to access custom props inside custom components */
  const { isDisabled, multiValueProperty, onRemoveValue } = props.selectProps;

  return (
    <Chip
      size="x-small"
      onCancel={() => {
        onRemoveValue(props.data.id);
      }}
      isDisabled={isDisabled}
      style={{
        // Allows for text overflow in a flex settings
        minWidth: '0',
      }}
    >
      {multiValueProperty ? (
        // When formatOptionLabel is applied to InputSelect, it applies to both control and options. Here, we avoid control to be formatted, and we simply display a property instead.
        props.data[multiValueProperty]
      ) : (
        <>
          {props.data.icon}
          {children}
        </>
      )}
    </Chip>
  );
};

const MultiValueRemove = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  ...props
}: MultiValueRemoveProps<Option, IsMulti, Group>) => {
  return (
    <components.MultiValueRemove<Option, IsMulti, Group> {...props}>
      <>{null}</>
    </components.MultiValueRemove>
  );
};

const Option = <
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: OptionProps<Option, IsMulti, Group>) => {
  /* @ts-ignore As mentioned in react-select docs https://react-select.com/components, it is the only way to access custom props inside custom components */
  const { styleVariant, size } = props.selectProps;
  return (
    <components.Option<Option, IsMulti, Group>
      {...props}
      className={element('option', {
        styleVariant,
        size,
        // TODO: Option generic type should be correctly typed to avoid using any
        isSticky: 'isStickyOption' in (props.data as any) ? true : false,
      })}
    >
      {children}
    </components.Option>
  );
};

const DropdownIndicator = <
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
) => {
  /* @ts-ignore As mentioned in react-select docs https://react-select.com/components, it is the only way to access custom props inside custom components */
  const { isDisabled, variant, isLoading } = props.selectProps;

  return variant === 'select' && !isLoading && !isDisabled ? (
    <components.DropdownIndicator<Option, IsMulti, Group> {...props}>
      <div className={element('chevron')}>
        <IconChevron down />
      </div>
    </components.DropdownIndicator>
  ) : null;
};

const ClearIndicator = <
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ClearIndicatorProps<Option, IsMulti, Group>
) => {
  /* @ts-ignore As mentioned in react-select docs https://react-select.com/components, it is the only way to access custom props inside custom components */
  const {
    innerProps: { ref, ...restInnerProps },
    /* @ts-ignore As mentioned in react-select docs https://react-select.com/components, it is the only way to access custom props inside custom components */
    selectProps: { variant },
  } = props;

  return (
    <components.ClearIndicator<Option, IsMulti, Group> {...props}>
      {variant === 'select' && (
        <div {...restInnerProps} ref={ref} className={element('clear')}>
          <IconCross />
        </div>
      )}
    </components.ClearIndicator>
  );
};

const LoadingIndicator = () => (
  <Loader
    style={{
      fontSize: '1rem',
    }}
  />
);

const Input = <
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: InputProps<Option, IsMulti, Group>
) => {
  // @ts-ignore autoComplete was added on our InputSelect, but react-select doesn't know about it
  return <components.Input {...props} autoComplete={props.selectProps.autoComplete} />;
};

export const InputSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  variant = 'select',
  tooltip,
  tooltipPlacement,
  styleVariant = 'default',
  placeholder,
  onRemoveValue,
  hintText,
  error,
  isInvalid,
  size = 'medium',
  style,
  ...props
}: InputSelectProps<Option, IsMulti, Group> & {
  autoComplete?: HTMLInputAutoCompleteAttribute;
}) => {
  const inputId = useId();

  return (
    <Stack style={style}>
      {(!!label || !!tooltip) && (
        <Stack row gap="0.25rem" style={{ marginBottom: '0.25rem' }}>
          {!!label && (
            <label
              htmlFor={inputId}
              className={size === 'large' ? 'paragraph-50-medium' : 'label-100'}
            >
              {label}{' '}
              {!!tooltip && (
                <Tooltip content={tooltip} placement={tooltipPlacement}>
                  <Button variant="style-less" style={{ position: 'relative', top: '3px' }}>
                    <IconInfo style={{ fontSize: '1rem' }} />
                  </Button>
                </Tooltip>
              )}
            </label>
          )}
        </Stack>
      )}

      <Select
        inputId={inputId}
        menuPosition="fixed"
        classNamePrefix={block()}
        aria-invalid={!!error || isInvalid}
        placeholder={<span className={element('placeholder', { size })}>{placeholder}</span>}
        /* @ts-ignore As mentioned in react-select docs https://react-select.com/components, it is the only way to give custom props access to custom components (ValueContainer in our case) */
        variant={variant}
        styleVariant={styleVariant}
        onRemoveValue={onRemoveValue}
        size={size}
        openMenuOnClick={['select', 'add'].includes(variant)}
        menuPlacement="auto"
        components={{
          Control,
          ValueContainer,
          MultiValueContainer,
          MultiValueRemove,
          DropdownIndicator,
          ClearIndicator,
          LoadingIndicator,
          Option,
          Input,
        }}
        unstyled
        noOptionsMessage={() => (
          <Stack
            className="paragraph-100-regular"
            padding="0.5rem"
            style={{ background: 'var(--color-neutral-0)' }}
          >
            <Trans id="input-select.no-results">No results</Trans>
          </Stack>
        )}
        loadingMessage={() => (
          <Stack
            className="paragraph-100-regular"
            padding="0.5rem"
            style={{ background: 'var(--color-neutral-0)' }}
          >
            <Trans id="input-select.loading">Loading...</Trans>
          </Stack>
        )}
        {...props}
      />

      {hintText && <Hint>{hintText}</Hint>}
      {error && <Message type="error">{error}</Message>}
    </Stack>
  );
};
