import { lazy, Suspense, useRef, useState } from 'react';
import { Button as AriaButton, DropZone, FileTrigger } from 'react-aria-components';
import { Trans, useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import IconDownload from '@/icons/Download.svg';
import IconFile from '@/icons/File.svg';
import IconFileNew from '@/icons/FileNew.svg';
import IconTrash from '@/icons/Trash.svg';
import IconZoom from '@/icons/Zoom.svg';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { usePanElement } from '@/utils/usePanElement';

import Loader from '../../components/Loader';

import './FileViewer.css';

const PdfDocument = lazy(() => import('./PdfDocument'));

const { block, element } = createBEMClasses('file-viewer');

const ACCEPTED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png', 'image/webp'];

export const FileViewer = ({
  files,
  onFileSelect,
  isUploading,
  onDelete,
  prompt,
  isInvalid,
  isDisabled,
}: {
  files: { id: string; type: 'pdf' | 'image'; url: string }[];
  onFileSelect?: (files: File[]) => void;
  isUploading?: boolean;
  onDelete?: (id: string) => void;
  prompt?: React.ReactNode;
  isInvalid?: boolean;
  isDisabled?: boolean;
}) => {
  const contentWrapperElement = useRef<HTMLDivElement>(null);

  // Base scale = scale to fit the widest file to the content wrapper
  const [maxWidth, setMaxWidth] = useState(0);
  const baseScale = ((contentWrapperElement.current?.clientWidth ?? 0) - 32) / (maxWidth || 1) || 1; // Includes 32px padding

  // Using exponents to avoid floating point precision issues with zooming in/out multiple times
  const [scaleExponent, setScaleExponent] = useState(0);
  const scale = baseScale * Math.pow(1.25, scaleExponent);

  usePanElement(contentWrapperElement);

  function zoom(zoom: -1 | 1) {
    setScaleExponent((prevScaleExponent) => prevScaleExponent + zoom);
  }

  return (
    <ErrorBoundary>
      <DropZone
        className={element('dropzone')}
        /**
         * For unknown reason, Safari returns an empty `types` array when a file is dropped.
         * Therefore, we cannot use this method as a reliable way to determine if all the dropped files are valid.
         * It is still an helpful visual helper for the user that works in most cases.
         *
         * Note: We keep `.some` instead of `.every` to handle the cases where the user uploads a mix of valid and invalid files.
         * In such cases, they will be filtered in the `onDrop` method.
         */
        getDropOperation={(types) =>
          onFileSelect && ACCEPTED_FILE_TYPES.some((type) => types.has(type)) ? 'copy' : 'cancel'
        }
        onDrop={async (e) => {
          const files = await Promise.all(
            e.items
              .filter((item) => item.kind === 'file')
              .filter((item) => ACCEPTED_FILE_TYPES.includes(item.type))
              .map(async (item) => await item.getFile())
          );

          if (files.length) {
            onFileSelect?.(files);
          }
        }}
        isDisabled={isDisabled}
      >
        <DropzonePrompt
          files={files}
          onFileSelect={onFileSelect}
          isUploading={isUploading}
          prompt={prompt}
        />
        <div
          className={block({
            empty: files.length === 0,
            isInvalid,
          })}
        >
          {/* Do not show controls until at least one file is loaded */}
          {files.length > 0 && (
            <Controls
              files={files}
              zoom={zoom}
              onFileSelect={onFileSelect}
              isUploading={isUploading}
              onDelete={onDelete}
              isDisabled={isDisabled}
            />
          )}
          <div className={element('content__wrapper')} ref={contentWrapperElement}>
            <div className={element('content')}>
              {files.map((file) => (
                <File
                  key={file.id}
                  file={file}
                  scale={scale}
                  onDelete={files.length > 1 ? () => onDelete?.(file.id) : undefined}
                  onLoad={(width) => {
                    setMaxWidth((prevMaxWidth) => Math.max(prevMaxWidth, width));
                  }}
                  isDisabled={isDisabled}
                />
              ))}
            </div>
          </div>
        </div>
      </DropZone>
    </ErrorBoundary>
  );
};

const File = ({
  file,
  scale,
  onDelete,
  onLoad: parentOnLoad,
  isDisabled,
}: {
  file: { id: string; url: string; type: 'pdf' | 'image' };
  scale: number;
  onDelete?: () => void;
  onLoad: (width: number) => void;
  isDisabled?: boolean;
}) => {
  const { t } = useLingui();

  const [url] = useState(file.url); // Prevents re-fetching when the public url is re-generated (because the file is the same)

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [width, setWidth] = useState(0);

  function onLoad(width: number) {
    setWidth(width);
    setIsLoading(false);
    setIsSuccess(true);
    parentOnLoad(width);
  }

  function onError() {
    setIsLoading(false);
  }

  return (
    <ErrorBoundary>
      <div className={element('content__file')}>
        {isLoading ? (
          <div className={element('content__file__loader')}>
            <Loader />
          </div>
        ) : (
          <div className={element('content__file__controls')}>
            {isSuccess && onDelete && !isDisabled && (
              <Button
                variant="secondary"
                iconOnly
                size="medium"
                onPress={onDelete}
                ariaLabel={t({
                  id: 'file-viewer.controls.delete',
                  message: 'Delete file',
                })}
              >
                <IconTrash />
              </Button>
            )}
          </div>
        )}
        {file.type === 'pdf' && (
          <Suspense>
            <PdfDocument url={url} scale={scale} onLoad={onLoad} onError={onError} />
          </Suspense>
        )}
        {file.type === 'image' && (
          <img
            src={url}
            alt=""
            style={{
              width: scale * width,
            }}
            onError={onError}
            onLoad={(e) => onLoad(e.currentTarget.naturalWidth)}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

const Controls = ({
  files,
  zoom,
  onFileSelect,
  isUploading,
  onDelete,
  isDisabled,
}: {
  files: { id: string; type: 'pdf' | 'image'; url: string }[];
  zoom: (zoom: -1 | 1) => void;
  onFileSelect?: (files: File[]) => void;
  isUploading?: boolean;
  onDelete?: (id: string) => void;
  isDisabled?: boolean;
}) => {
  const { t } = useLingui();

  return (
    <div className={element('controls')}>
      <div className={element('controls__top-left')}>
        {onFileSelect && !isDisabled && (
          <FileTrigger
            onSelect={(fileList) => {
              if (fileList) {
                onFileSelect(Array.from(fileList));
              }
            }}
            acceptedFileTypes={ACCEPTED_FILE_TYPES}
            allowsMultiple
          >
            <Button variant="secondary" size="small" isLoading={isUploading}>
              <IconAdd />
              <Trans id="file-viewer.add-file">Add another file</Trans>
            </Button>
          </FileTrigger>
        )}
      </div>
      <div className={element('controls__top-right')}>
        <Button
          variant="secondary"
          iconOnly
          size="medium"
          ariaLabel={t({
            id: 'file-viewer.controls.download',
            message: 'Download',
          })}
        >
          <IconDownload />
        </Button>
        <Button
          variant="secondary"
          iconOnly
          size="medium"
          onPress={() => zoom(-1)}
          ariaLabel={t({
            id: 'file-viewer.controls.zoom-out',
            message: 'Zoom out',
          })}
        >
          <IconZoom variant="out" />
        </Button>
        <Button
          variant="secondary"
          iconOnly
          size="medium"
          onPress={() => zoom(1)}
          ariaLabel={t({
            id: 'file-viewer.controls.zoom-in',
            message: 'Zoom in',
          })}
        >
          <IconZoom variant="in" />
        </Button>
        {files.length === 1 && onDelete && !isDisabled && (
          <Button
            variant="secondary"
            iconOnly
            size="medium"
            onPress={() => onDelete(files[0].id)}
            ariaLabel={t({
              id: 'file-viewer.controls.delete',
              message: 'Delete file',
            })}
          >
            <IconTrash />
          </Button>
        )}
      </div>
    </div>
  );
};

const DropzonePrompt = ({
  files,
  onFileSelect,
  isUploading,
  prompt,
}: {
  files: { id: string; type: 'pdf' | 'image'; url: string }[];
  onFileSelect?: (files: File[]) => void;
  isUploading?: boolean;
  prompt?: React.ReactNode;
}) => {
  return (
    <div className={element('dropzone__prompt')}>
      <div className={element('dropzone__prompt__drop')}>
        <Stack gap="1rem" alignItems="center">
          <IconFileNew />
          <p>
            <Trans id="file-viewer.dropzone.prompt.drop">Drop the file here</Trans>
          </p>
        </Stack>
      </div>
      {files.length === 0 && (
        <FileTrigger
          onSelect={(fileList) => {
            if (fileList) {
              onFileSelect?.(Array.from(fileList));
            }
          }}
          acceptedFileTypes={ACCEPTED_FILE_TYPES}
          allowsMultiple
        >
          <AriaButton className={element('dropzone__prompt__button')} isDisabled={isUploading}>
            <Stack row gap="0.5rem" alignItems="center">
              {isUploading ? (
                <>
                  <Loader delay={0} />
                  <p>
                    <Trans id="file-viewer.dropzone.prompt.uploading">Uploading...</Trans>
                  </p>
                </>
              ) : (
                (prompt ?? (
                  <>
                    <IconFile />
                    <p>
                      <Trans id="file-viewer.dropzone.prompt.add">Add files</Trans>
                    </p>
                  </>
                ))
              )}
            </Stack>
          </AriaButton>
        </FileTrigger>
      )}
    </div>
  );
};
