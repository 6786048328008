import { CSSProperties } from 'react';
import { useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useInvoicesOrigins } from '@/models/invoice';

export const InvoiceLineWorkshopSelect = ({
  setPage,
  invoiceLineWorkshop,
  setInvoiceLineWorkshop,
  style,
}: {
  setPage?: (page: number) => void;
  invoiceLineWorkshop: string;
  setInvoiceLineWorkshop: (workshop: string) => void;
  style?: CSSProperties;
}) => {
  const { t } = useLingui();

  const { data: invoicesOrigins, isFetching } = useInvoicesOrigins();

  const options = (invoicesOrigins?.workshops ?? []).sort((a, b) => a.name.localeCompare(b.name));

  const selectedOption = options.find((option) => option.id === invoiceLineWorkshop) ?? null;

  if (!options.length) {
    return null;
  }

  return (
    <InputSelect
      variant="select"
      placeholder={t({
        id: 'accounting.invoice-lines.workshop.placeholder',
        message: 'Workshop: All',
      })}
      aria-label={t({ id: 'accounting.invoice-lines.workshop.label', message: 'Workshop' })}
      value={selectedOption}
      isClearable
      options={options}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      onChange={(option, action) => {
        if (action.action === 'clear') {
          setInvoiceLineWorkshop('');
        } else if (option) {
          setInvoiceLineWorkshop(option.id);
        }

        setPage?.(1);
      }}
      isLoading={isFetching}
      style={style}
    />
  );
};
