import { FeeTypeSlug } from '@/api';

import { Model } from './model';
import { Translation } from './translation';

export class Fee extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
}

export class FeeType extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);

    this.name = new Translation(data.name);
  }

  id!: string;
  slug!: FeeTypeSlug;
  name!: Translation;
}
