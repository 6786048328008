import { CSSProperties, useContext, useState } from 'react';
import {
  Button as AriaButton,
  Dialog as AriaDialog,
  DialogTrigger,
  Modal,
  ModalOverlay,
  OverlayTriggerStateContext,
} from 'react-aria-components';
import { useLingui } from '@lingui/react/macro';

import Button from '@/design_system/Button';
import CloseConfirmation from '@/design_system/Dialog/CloseConfirmation';
import CloseIcon from '@/icons/Cross.svg';
import { createBEMClasses } from '@/utils/classname';
import { useWebkitVirtualKeyboardHeight } from '@/utils/useWebkitVirtualKeyboardHeight';

import './Drawer.css';

const { block, element } = createBEMClasses('drawer');

interface DrawerProps {
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => any;
  animateEntry?: boolean;
  trigger?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  closeConfirmation?: {
    title: React.ReactNode;
    content: React.ReactNode;
    confirm: React.ReactNode;
  };
  position?: 'right' | 'bottom';
}

const Drawer = ({
  isOpen,
  onOpenChange,
  animateEntry = true,
  children,
  trigger,
  style,
  closeConfirmation,
  position = 'right',
}: DrawerProps) => {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const isChrome = navigator.userAgent.match(/chrome|chromium|crios/i);

  return (
    <>
      <DialogTrigger
        isOpen={isOpen}
        onOpenChange={(opened) => {
          if (!opened && closeConfirmation) {
            setShowCloseConfirmation(true);
          } else {
            onOpenChange?.(opened);
          }
        }}
      >
        {trigger ?? (
          <AriaButton style={{ display: 'none' }}>
            {/* Warning when no trigger, so we add a hidden button */}
          </AriaButton>
        )}
        <ModalOverlay
          className={element('overlay', { 'animate-entry': animateEntry })}
          isDismissable
        >
          <Modal className={block({ 'animate-entry': animateEntry, position })} style={style}>
            <AriaDialog>
              {/* Hide behaviour on chrome when after a button press that changes some state the first element of the dialog gets focused */}
              {isChrome && <input type="text" style={{ position: 'absolute', top: '-999px' }} />}
              {children}
              <DrawerClose />
            </AriaDialog>
          </Modal>
        </ModalOverlay>
      </DialogTrigger>
      {closeConfirmation && (
        <CloseConfirmation
          title={closeConfirmation.title}
          content={closeConfirmation.content}
          confirm={closeConfirmation.confirm}
          onConfirm={() => {
            setShowCloseConfirmation(false);
            onOpenChange?.(false);
          }}
          showDialog={showCloseConfirmation}
          setShowDialog={setShowCloseConfirmation}
        />
      )}
    </>
  );
};

export default Drawer;

export const DrawerHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <header slot="title" className={element('header', undefined, className)}>
    {children}
  </header>
);

export const DrawerBody = ({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => (
  <main className={element('body', undefined, className)} style={style}>
    {children}
  </main>
);

export const DrawerFooter = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  const webkitKeyboardHeight = useWebkitVirtualKeyboardHeight();

  return (
    <footer
      className={element('footer', undefined, className)}
      style={{
        marginBottom: `${webkitKeyboardHeight}px`,
      }}
    >
      {children}
    </footer>
  );
};

const DrawerClose = () => {
  const { t } = useLingui();
  const state = useContext(OverlayTriggerStateContext);

  return (
    <Button
      onPress={() => state?.close()}
      ariaLabel={t({ id: 'design-system.drawer.close', message: 'Close drawer' })}
      iconOnly
      size="medium"
      variant="neutral"
      className={element('close-button')}
    >
      <CloseIcon className={element('close-icon')} />
    </Button>
  );
};
