import { Flags } from '@/api';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { UserWithRelations } from '@/models/user';
import { Workflow } from '@/models/workflow';
import {
  RequestArticleContextData,
  RequestContextData,
} from '@/routes/Requests/contexts/interfaces';

export const computeAcceptDispatchContext = ({
  workflow,
  view,
  currentSession,
  flags,
}: {
  request: RequestWithRelations;
  requestView: RequestContextData['view'];
  workflow?: Workflow;
  article: ArticleWithRelations;
  view: RequestArticleContextData['view'];
  currentSession: UserWithRelations;
  flags: Flags;
}) => {
  view.warranty.editable = false;

  /**
   * SERVICES
   */
  view.services.defects.shown = !!workflow?.config.defectsEnabled;
  view.services.defects.editable = false;

  view.services.choice.shown = false;

  view.services.actions.shown = true;
  view.services.actions.editable = true;
  view.services.actions.canAddRemoveAction = false;
  view.services.actions.canEditDefects = false;
  view.services.actions.canEditCost =
    !currentSession.workshop && flags['enable-dispatch-cost-edition'];
  view.services.actions.canEditPrice =
    !!currentSession.workshop && flags['enable-dispatch-cost-edition'];

  view.services.dispatch.shown = false;

  view.services.none.shown = false;
};
