import { CSSProperties, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTrigger,
  GridList,
  GridListItem,
  Label,
  Popover,
  Separator,
} from 'react-aria-components';
import {
  CalendarDate,
  endOfMonth,
  getLocalTimeZone,
  parseAbsoluteToLocal,
  parseTime,
  startOfMonth,
  toCalendarDate,
  toCalendarDateTime,
  today,
} from '@internationalized/date';
import { msg } from '@lingui/core/macro';
import { useLingui } from '@lingui/react/macro';
import { parseISO } from 'date-fns';

import IconCalendar from '@/icons/Calendar.svg';
import IconCheck from '@/icons/Check.svg';
import IconChevron from '@/icons/Chevron.svg';
import { createBEMClasses } from '@/utils/classname';
import { formatDateRange } from '@/utils/date';

import Hint from '../Hint';
import Message, { MessageProps } from '../Message';

import { RangeCalendar } from './Calendar';
import { CommonDateFilterProps } from './InputDate';

import './InputDateRange.css';

const { block, element } = createBEMClasses('input-date-range');

export type DateRangeValue = {
  /**
   * The user selects two days, without specifying a time.
   * For `start` we set the time at midnight on the user's local timezone.
   * For `end` we set the time at 23:59 on the user's local timezone.
   * Both `start` and `end` are formatted as ISO 8601 strings on the UTC timezone (Z).
   */
  start: string;
  end: string;
};

export const calendarDateToIsoStringRange = (value: {
  start: CalendarDate;
  end: CalendarDate;
}) => ({
  start: toCalendarDateTime(value.start, parseTime('00:00:00'))
    .toDate(getLocalTimeZone())
    .toISOString(),
  end: toCalendarDateTime(value.end, parseTime('23:59:59'))
    .toDate(getLocalTimeZone())
    .toISOString(),
});

export const isoStringToCalendarDateRange = (value: { start: string; end: string }) => {
  let start: CalendarDate | undefined = undefined;
  let end: CalendarDate | undefined = undefined;

  if (value) {
    try {
      start = toCalendarDate(parseAbsoluteToLocal(value.start));
      end = toCalendarDate(parseAbsoluteToLocal(value.end));

      return { start, end };
    } catch (e) {
      console.error(e);
    }
  }

  return undefined;
};

export const isoStringToDateString = (isoString: string) => {
  return toCalendarDate(parseAbsoluteToLocal(isoString)).toString();
};

const MENU_OPTIONS = [
  {
    id: 'any',
    label: msg({ id: 'design-system.input-date-range.option.any', message: 'All' }),
    getRange: () => undefined,
  },
  {
    id: 'last-7-days',
    label: msg({ id: 'design-system.input-date-range.option.last-7-days', message: 'Last 7 days' }),
    getRange: () =>
      calendarDateToIsoStringRange({
        start: today(getLocalTimeZone()).subtract({ days: 7 }),
        end: today(getLocalTimeZone()),
      }),
  },
  {
    id: 'current-month',
    label: msg({
      id: 'design-system.input-date-range.option.current-month',
      message: 'Current month',
    }),
    getRange: () =>
      calendarDateToIsoStringRange({
        start: startOfMonth(today(getLocalTimeZone())),
        end: endOfMonth(today(getLocalTimeZone())),
      }),
  },
  {
    id: 'past-month',
    label: msg({ id: 'design-system.input-date-range.option.past-month', message: 'Past month' }),
    getRange: () =>
      calendarDateToIsoStringRange({
        start: startOfMonth(today(getLocalTimeZone()).subtract({ months: 1 })),
        end: endOfMonth(today(getLocalTimeZone()).subtract({ months: 1 })),
      }),
  },
  {
    id: 'custom',
    label: msg({ id: 'design-system.input-date-range.option.custom', message: 'Custom' }),
    getRange: () => undefined,
  },
] as const;

const STANDARD_OPTIONS = MENU_OPTIONS.filter((option) => option.id !== 'custom');
const CUSTOM_OPTION = MENU_OPTIONS.find((option) => option.id === 'custom')!;

type MenuOption = (typeof MENU_OPTIONS)[number]['id'];

export type InputDateRangeValue = {
  option: MenuOption;
} & Partial<DateRangeValue>;

export type InputDateRangeProps = CommonDateFilterProps & {
  value?: InputDateRangeValue;
  onChange?: (value: InputDateRangeValue) => void;

  label?: ReactNode;
  ariaLabel?: string;
  placeholder?: string;

  hintText?: string;
  error?: string;
  messageType?: MessageProps['type'];
  messageText?: string;
  showMessageIcon?: boolean;

  isDisabled?: boolean;
  isInvalid?: boolean;

  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'brand';

  className?: string;
  style?: CSSProperties;
};

const InputDateRange = ({
  value,
  onChange,
  minValue,
  maxValue,
  isDateUnavailable,
  label,
  ariaLabel,
  placeholder,
  hintText,
  error,
  messageType,
  messageText,
  showMessageIcon,
  isDisabled,
  isInvalid,
  size = 'medium',
  variant = 'default',
  className,
  style,
}: InputDateRangeProps) => {
  const { i18n, t } = useLingui();

  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<'menu' | 'calendar'>('menu');

  const [buttonNode, setButtonNode] = useState<HTMLButtonElement | null>(null);
  const ref = useCallback((node: HTMLButtonElement) => {
    if (node !== null) {
      setButtonNode(node);
    }
  }, []);

  const handleMenuOptionClick = (option: MenuOption) => {
    const optionData = MENU_OPTIONS.find(({ id }) => id === option);

    if (!optionData) {
      return;
    }

    if (option === 'custom') {
      setStep('calendar');
      return;
    }

    const range = optionData.getRange();

    onChange?.({
      option,
      start: range?.start,
      end: range?.end,
    });

    setIsOpen(false);
  };

  const handleCalendarChange = (range: DateRangeValue | undefined) => {
    setIsOpen(false);

    if (range) {
      onChange?.({
        option: 'custom',
        ...range,
      });
    }
  };

  const selectedOption = !value ? 'any' : value.option;

  const valueLabel = useMemo(() => {
    const start = value?.start ? parseISO(value.start) : undefined;
    const end = value?.end ? parseISO(value.end) : undefined;
    const option = MENU_OPTIONS.find(({ id }) => id === value?.option);

    if (!option || option.id === 'any' || !start || !end) {
      return (
        placeholder ??
        t({ id: 'design-system.input-date-range.option.placeholder', message: 'Date: All' })
      );
    }

    if (option.id === 'custom') {
      return formatDateRange(start, end, { dateStyle: 'medium' });
    }

    return i18n._(option.label);
  }, [i18n, placeholder, t, value]);

  return (
    <div
      aria-label={ariaLabel}
      className={block({ size, variant, invalid: isInvalid || !!error }, className)}
      style={style}
    >
      {!!label && (
        <Label className={size === 'large' ? 'paragraph-50-medium' : 'label-100'}>{label}</Label>
      )}

      <DialogTrigger
        isOpen={isOpen}
        onOpenChange={(isOpen) => {
          setIsOpen(isOpen);
          setStep('menu');
        }}
      >
        <Button
          isDisabled={isDisabled}
          className={element(
            'trigger',
            { placeholder: !value || value?.option === 'any' },
            'text-ellipsis'
          )}
          ref={ref}
        >
          <IconCalendar />
          <span>{valueLabel}</span>
          <IconChevron down />
        </Button>

        <Popover offset={4}>
          <Dialog style={{ outline: 'none' }}>
            {step === 'menu' && (
              <GridList
                onAction={(key) => handleMenuOptionClick(key as MenuOption)}
                className={element('menu', { variant })}
                aria-label={t({
                  id: 'design-system.input-date-range.option.menu-label',
                  message: 'Date range options',
                })}
                style={{ minWidth: buttonNode?.getBoundingClientRect().width }}
              >
                {STANDARD_OPTIONS.map((option) => (
                  <GridListItem
                    id={option.id}
                    key={option.id}
                    className={element('menu__item', { selected: selectedOption === option.id })}
                    textValue={i18n._(option.label)}
                  >
                    <IconCalendar />
                    {i18n._(option.label)}
                    {selectedOption === option.id && <IconCheck />}
                  </GridListItem>
                ))}
                <Separator className={element('menu__separator')} />
                <GridListItem
                  id={CUSTOM_OPTION.id}
                  className={element('menu__item', {
                    selected: selectedOption === CUSTOM_OPTION.id,
                  })}
                  textValue={i18n._(CUSTOM_OPTION.label)}
                >
                  {i18n._(CUSTOM_OPTION.label)}
                  {selectedOption === CUSTOM_OPTION.id && <IconCheck />}
                </GridListItem>
              </GridList>
            )}

            {step === 'calendar' && (
              <RangeCalendar
                value={
                  value?.start && value?.end ? { start: value.start, end: value.end } : undefined
                }
                onChange={handleCalendarChange}
                minValue={minValue}
                maxValue={maxValue}
                isDateUnavailable={isDateUnavailable}
                embedded
                variant={variant}
                size={size === 'large' ? 'large' : 'medium'}
              />
            )}
          </Dialog>
        </Popover>
      </DialogTrigger>

      {!!error && (
        <Message type="error" showMessageIcon={showMessageIcon}>
          {error}
        </Message>
      )}
      {hintText && <Hint>{hintText}</Hint>}
      {messageText && <Message type={messageType}>{messageText}</Message>}
    </div>
  );
};

export default InputDateRange;
