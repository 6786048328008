import { GridList, GridListItem } from 'react-aria-components';
import { useNavigate } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import { ArticleNameCell, ArticleWorkshopCell } from '@/components/ArticlesTableCells';
import BaseMoneyCell, {
  ArticleCostCardItem,
  ArticlePriceCardItem,
} from '@/components/ArticlesTableCells/ArticleMoneyCell/ArticleMoneyCell';
import {
  ArticleServiceTypeCardItem,
  ArticleServiceTypeCell,
} from '@/components/ArticlesTableCells/ArticleServiceTypeCell';
import { ArticleWorkshopCardItem } from '@/components/ArticlesTableCells/ArticleWorkshopCell';
import { Card, CardContent, CardItems } from '@/components/Card/Card';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconChevron from '@/icons/Chevron.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import { useRequestContext } from '@/routes/Requests/contexts/RequestContext';
import { createBEMClasses } from '@/utils/classname';

import './CompletedArticlesTable.css';

const { block, element } = createBEMClasses('completed-articles-table');

export const CompletedArticlesTable = ({ articles }: { articles: ArticleWithRelations[] }) => {
  const { t } = useLingui();
  const {
    view: { workshop, price },
  } = useRequestContext();

  return (
    <Table
      ariaLabel={t({ id: 'request.articles.table.label', message: 'Items' })}
      columnWidths={[
        'minmax(200px, 1fr)',
        'minmax(120px, 1fr)',
        workshop.enabled && 'minmax(120px, 1fr)',
        price.enabled && 'minmax(auto, 120px)',
      ]}
      className={block()}
    >
      <CompletedArticlesTableHeader />
      <Body>
        {articles.map((article) => (
          <CompletedArticlesTableRow key={article.id} article={article} />
        ))}
      </Body>
    </Table>
  );
};

const CompletedArticlesTableHeader = () => {
  const {
    view: { workshop, price },
  } = useRequestContext();

  return (
    <Header>
      <Row>
        <Column>
          <Trans id="request.articles.table.label">Items</Trans>
        </Column>
        <Column>
          <Trans id="request.articles.table.column.service-type.label">Service Type</Trans>
        </Column>
        {workshop.enabled && (
          <Column>
            <Trans id="request.articles.table.column.workshop.label">Workshop</Trans>
          </Column>
        )}
        {price.enabled && <Column justifyContent="end">{price.label}</Column>}
      </Row>
    </Header>
  );
};

const CompletedArticlesTableRow = ({ article }: { article: ArticleWithRelations }) => {
  const {
    view: { workshop, price, cost },
  } = useRequestContext();

  return (
    <Row to={`/requests/${article.requestId}/articles/${article.id}`}>
      <Cell isLink>
        <ArticleNameCell article={article} />
      </Cell>
      <Cell>
        <ArticleServiceTypeCell article={article} />
      </Cell>
      {workshop.enabled && (
        <Cell>
          <ArticleWorkshopCell
            article={article}
            showWorkshopPrice={cost.enabled}
            showAmountBeforeTaxes={cost.showAmountBeforeTaxes}
          />
        </Cell>
      )}
      {price.enabled && (
        <Cell justifyContent="end">
          <BaseMoneyCell
            price={article.snapshot.price?.amountPerCurrency[0]}
            showAmountBeforeTaxes={price.showAmountBeforeTaxes}
          />
        </Cell>
      )}
    </Row>
  );
};

export const CompletedArticlesCardList = ({ articles }: { articles: ArticleWithRelations[] }) => {
  const navigate = useNavigate();
  const { request } = useRequestContext();
  const { t } = useLingui();

  return (
    <GridList
      className={block()}
      onAction={(key) => {
        navigate(`/requests/${request.id}/articles/${key}`);
      }}
      aria-label={t({
        id: 'request.articles.table.waiting-tasks.label',
        message: 'Waiting for others',
      })}
    >
      {articles.map((article) => (
        <ArticleCard key={article.id} article={article} />
      ))}
    </GridList>
  );
};

const ArticleCard = ({ article }: { article: ArticleWithRelations }) => {
  const {
    view: { workshop, price, cost },
  } = useRequestContext();

  const articleName = useArticleName({ article });

  return (
    <GridListItem id={article.id} textValue={articleName} className={element('item')}>
      <Card>
        <CardContent>
          <Stack
            row
            gap="0.5rem"
            flexWrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <ArticleNameCell id={article.id} article={article} />
            <IconChevron right style={{ fontSize: '1.5rem' }} />
          </Stack>
          <CardItems>
            {article.hasActions && <ArticleServiceTypeCardItem article={article} />}
            {workshop.enabled && <ArticleWorkshopCardItem article={article} />}
            {cost.enabled && (
              <ArticleCostCardItem
                article={article}
                costLabel={cost.label}
                showAmountBeforeTaxes={cost.showAmountBeforeTaxes}
              />
            )}
            {price.enabled && (
              <ArticlePriceCardItem
                article={article}
                priceLabel={price.label}
                showAmountBeforeTaxes={price.showAmountBeforeTaxes}
              />
            )}
          </CardItems>
        </CardContent>
      </Card>
    </GridListItem>
  );
};
