import { useLingui } from '@lingui/react/macro';

import { EmptyState } from '@/components/EmptyState';

import { IllustrationInvoicesEmptyState } from './IllustrationInvoicesEmptyState';

export const InvoicesEmptyState = () => {
  const { t } = useLingui();

  return (
    <EmptyState
      subtitle={t({
        id: 'accounting.invoices.empty-state.subtitle',
        message: 'No invoices to display',
      })}
      illustration={<IllustrationInvoicesEmptyState />}
    />
  );
};
