import { useLocation, useNavigate } from 'react-router';
import { Trans, useLingui } from '@lingui/react/macro';

import config from '@/config';
import Button from '@/design_system/Button';
import { Variant } from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import IconDownload from '@/icons/Download.svg';
import IconInvoice from '@/icons/Invoice.svg';
import IconWarning from '@/icons/Warning.svg';
import { Invoice, useCreateInvoice, useInvoices } from '@/models/invoice';
import { useArticleContext } from '@/routes/Requests/contexts/ArticleContext';
import { useCurrentSession } from '@/services/auth';

export const ArticleWorkshopPayment = () => {
  const { article, request } = useArticleContext();
  const { currentSession } = useCurrentSession();

  const { data: { invoices } = { invoices: [] }, isLoading } = useInvoices({
    articleId: article.id,
    originWorkshop: article.workshop!.id,
  });

  const canCreateInvoice = currentSession?.hasPermission('create_invoice', [
    { organizationId: article.organizationId },
    { storeId: request.storeId },
  ]);

  return (
    <Stack gap="12px">
      <WorkshopPaymentStrategy />
      <WorkshopInvoices invoices={invoices} isLoading={isLoading} />
      {canCreateInvoice && !isLoading && invoices.length === 0 && <WorkshopUploadInvoice />}
    </Stack>
  );
};

const WorkshopPaymentStrategy = () => {
  const { article } = useArticleContext();

  if (!article.workshopPaymentStrategy) {
    return (
      <Stack>
        <Stack row gap="4px" alignItems="center" className="paragraph-200-regular text-warning">
          <IconWarning />
          <Trans id="article.workshop-payment.strategy.label">Payment responsible</Trans>
        </Stack>
        <p className="paragraph-100-medium">-</p>
      </Stack>
    );
  }

  return (
    <Stack>
      <p className="paragraph-200-regular">
        <Trans id="article.workshop-payment.strategy.label">Payment responsible</Trans>
      </p>
      <p className="paragraph-100-medium">
        {
          {
            'store-cash-out': (
              <Trans id="article.workshop-payment.strategy.store-cash-out">The store</Trans>
            ),
            'handled-by-accounting-team': (
              <Trans id="article.workshop-payment.strategy.handled-by-accounting-team">
                The accounting department
              </Trans>
            ),
            'prolong-payout': (
              <Trans id="article.workshop-payment.strategy.prolong-payout">Prolong</Trans>
            ),
            'prolong-payin': '-', // Not supposed to happen
          }[article.workshopPaymentStrategy]
        }
      </p>
    </Stack>
  );
};

const WorkshopInvoices = ({ invoices, isLoading }: { invoices: Invoice[]; isLoading: boolean }) => {
  const navigate = useNavigate();
  const { t } = useLingui();

  if (isLoading) {
    return null;
  }

  if (!invoices.length) {
    return (
      <Stack>
        <Stack row gap="4px" alignItems="center" className="paragraph-200-regular text-warning">
          <IconWarning />
          <Trans id="article.workshop-payment.invoice.label">Invoice from workshop</Trans>
        </Stack>
        <p className="paragraph-100-medium">-</p>
      </Stack>
    );
  }

  return (
    <Stack>
      <p className="paragraph-200-regular">
        <Trans id="article.workshop-payment.invoice.label">Invoice from workshop</Trans>
      </p>
      {invoices.map((invoice) => (
        <Stack key={invoice.id} row alignItems="center" flexWrap="nowrap" gap="4px">
          <p className="paragraph-100-medium text-ellipsis" title={invoice.number}>
            {invoice.number}
          </p>
          <Button
            variant="secondary"
            size="small"
            iconOnly
            href={`${config.apiUrl}/invoices/${invoice.id}/media`}
            target="_blank"
            ariaLabel={t({ id: 'article.workshop-payment.invoice.download', message: 'Download' })}
            tooltip={t({ id: 'article.workshop-payment.invoice.download', message: 'Download' })}
          >
            <IconDownload />
          </Button>
          <Button
            variant="secondary"
            size="small"
            iconOnly
            onPress={() =>
              navigate(`/accounting/invoices/${invoice.id}`, {
                state: {
                  onCancel: location.pathname,
                },
              })
            }
            ariaLabel={t({
              id: 'article.workshop-payment.invoice.details',
              message: 'View details',
            })}
            tooltip={t({
              id: 'article.workshop-payment.invoice.details',
              message: 'View details',
            })}
          >
            <IconInvoice success />
          </Button>
        </Stack>
      ))}
    </Stack>
  );
};

export const WorkshopUploadInvoice = ({ variant = 'secondary' }: { variant?: Variant }) => {
  const { article, request } = useArticleContext();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutateAsync: createInvoice, isPending } = useCreateInvoice();

  const handleCreateInvoice = async () => {
    const { id } = await createInvoice({
      paymentStrategy: article.workshopPaymentStrategy!,
      originWorkshopId: article.workshop!.id,
      destinationOrganizationCountryId: request.organizationCountryId!,
    });

    navigate(`/accounting/invoices/${id}`, {
      state: {
        onCancel: location.pathname,
      },
    });
  };

  return (
    <Button variant={variant} size="small" onPress={handleCreateInvoice} isLoading={isPending}>
      <IconInvoice new />
      <Trans id="article.workshop-payment.upload-invoice">Upload workshop&apos;s invoice</Trans>
    </Button>
  );
};
