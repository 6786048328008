import IconWrapper from './IconWrapper';

const IconStripe = IconWrapper({
  name: 'stripe',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 2C3.83696 2 3.20107 2.26339 2.73223 2.73223C2.26339 3.20107 2 3.83696 2 4.5L2 19.5C2 20.163 2.26339 20.7989 2.73223 21.2678C3.20107 21.7366 3.83696 22 4.5 22H19.5C20.163 22 20.7989 21.7366 21.2678 21.2678C21.7366 20.7989 22 20.163 22 19.5V4.5C22 3.83696 21.7366 3.20107 21.2678 2.73223C20.7989 2.26339 20.163 2 19.5 2H4.5ZM12.2825 8.73125C11.5525 8.73125 11.1112 8.93625 11.1112 9.4725C11.1112 10.0575 11.87 10.315 12.8113 10.635C14.3463 11.1538 16.3663 11.8387 16.375 14.3763C16.375 16.835 14.405 18.25 11.5375 18.25C10.2439 18.2446 8.96468 17.9784 7.77625 17.4675V14.1975C8.93375 14.83 10.395 15.2975 11.5387 15.2975C12.31 15.2975 12.8612 15.0913 12.8612 14.4587C12.8612 13.8112 12.0387 13.515 11.045 13.1575C9.5325 12.6125 7.625 11.925 7.625 9.6375C7.625 7.20625 9.485 5.75 12.2825 5.75C13.4516 5.73954 14.6118 5.95384 15.7 6.38125V9.61C14.6525 9.0475 13.33 8.73125 12.2825 8.73125Z"
        fill="black"
      />
    </svg>
  ),
});

export default IconStripe;
