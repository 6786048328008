import { CSSProperties } from 'react';
import { useLingui } from '@lingui/react/macro';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useInvoicesDestinations } from '@/models/invoice';

export const InvoiceLineOrganizationCountrySelect = ({
  setPage,
  invoiceLineOrganizationCountry,
  setInvoiceLineOrganizationCountry,
  style,
}: {
  setPage?: (page: number) => void;
  invoiceLineOrganizationCountry: string;
  setInvoiceLineOrganizationCountry: (organizationCountry: string) => void;
  style?: CSSProperties;
}) => {
  const { t } = useLingui();

  const { data: invoicesDestinations, isFetching } = useInvoicesDestinations();

  const options = (invoicesDestinations?.organizationCountries ?? []).sort((a, b) =>
    (a.name ?? '').localeCompare(b.name ?? '')
  );

  const selectedOption =
    options.find((option) => option.id === invoiceLineOrganizationCountry) ?? null;

  if (!options.length) {
    return null;
  }

  return (
    <InputSelect
      variant="select"
      placeholder={t({
        id: 'accounting.invoice-lines.organization-country.placeholder',
        message: 'Organization: All',
      })}
      aria-label={t({
        id: 'accounting.invoice-lines.organization-country.label',
        message: 'Organization',
      })}
      value={selectedOption}
      isClearable
      options={options}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name ?? ''}
      onChange={(option, action) => {
        if (action.action === 'clear') {
          setInvoiceLineOrganizationCountry('');
        } else if (option) {
          setInvoiceLineOrganizationCountry(option.id);
        }

        setPage?.(1);
      }}
      isLoading={isFetching}
      style={style}
    />
  );
};
